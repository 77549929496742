
  import { defineComponent } from "vue";

  export default defineComponent({
    props: {
      tags: {
        type: Array,
        require: true,
        default: () => []
      },
    },
    setup() {
      return {};
    },
  });
