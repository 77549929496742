import { News, Upload } from "@/payload-types";
import { Where } from "@/types";

export function getTags(article: News): string[] {
    const tags: string[] = [];

    if (article.location) {
        for (const location of article.location) {
            if (typeof location !== "string" && location.name != undefined) {
                tags.push(location.label ?? location.name);
            }
        }
    }

    if (article.locationFilterType === 'global') {
        tags.push('Corporate');
    }

    if (article._status === 'draft') {
        tags.push('Draft');
    }
    return tags;
}

export function buildLocationalWhereQuery(locations: string[] = []): Where {
    return {
        or: [
            {
                locationFilterType: {
                    equals: "global",
                },
            },
            {
                or: locations.map((location) => {
                    return {
                        location: {
                            equals: location,
                        },
                    };
                }),
            },
        ],
    };
}

export function buildStatusQuery(includeDrafts = false): Where {
    if (includeDrafts) {
        return {};
    }

    return {
        _status: {
            equals: "published",
        }
    }
}

export function buildStartAndEndQuery(date: Date, options: { preview?: boolean } = {}): Where {
    if (options.preview) {
        return {};
    } else {
        return {
            startDate: {
                less_than_equal: date.getTime(),
            },
            or: [
                {
                    endDate: {
                        greater_than_equal: date,
                    }
                },
                {
                    endDate: {
                        equals: null,
                    }
                }
            ]
        };
    }
}

export function getFeaturedImage(article: News | string): Upload | undefined {
    if (typeof article === 'string') {
        return undefined;
    }

    if (typeof article.featuredImage === 'string') {
        return undefined;
    }

    return article.featuredImage;
}