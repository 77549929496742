export const parseEnvArray = (value: string) => {
    const envValue = process.env?.[value];

    try {
      const object = JSON.parse(envValue);

      if (!Array.isArray(object)) {
        throw new Error('Expected an array');
      }

      return object;
    } catch(e) {
      console.warn('Could not parse env variable', e);
      return [];
    }
}

export const sortCategoriesByLabel = (arr: {label: string, value: string}[], labelOrder: string[]) => {
  const labelMap = new Map(labelOrder.map((label: string, index: number) => [label, index]));
  arr.sort((a: any, b: any) => {
    const aIndex:any = labelMap.get(a.label);
    const bIndex:any = labelMap.get(b.label);
    return aIndex - bIndex;
  });
  
  return arr;
}