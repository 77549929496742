import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_resource_group = _resolveComponent("resource-group")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (
      _ctx.selectedDepartmentId != undefined && _ctx.selectedDepartmentLabel != undefined
    )
      ? (_openBlock(), _createBlock(_component_resource_group, {
          key: 0,
          class: "card-section department",
          onOnclick: _ctx.emitClickEvent,
          resourceType: _ctx.resourceType,
          category: {
      value: _ctx.selectedDepartmentId,
      label: _ctx.selectedDepartmentLabel,
    },
          departmentFilterType: "divisional",
          sectionTitlePrefix: "Division"
        }, null, 8, ["onOnclick", "resourceType", "category"]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
      return (_openBlock(), _createBlock(_component_resource_group, {
        onOnclick: _ctx.emitClickEvent,
        key: category.value,
        class: "card-section corporate",
        resourceType: _ctx.resourceType,
        category: category,
        departmentFilterType: "global"
      }, null, 8, ["onOnclick", "resourceType", "category"]))
    }), 128))
  ], 64))
}