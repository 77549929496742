import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["alt", "src", "srcset", "sizes"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    alt: _ctx.alt ?? '',
    src: _ctx.base64Image || _ctx.fallbackUrl,
    srcset: (!_ctx.base64Image && _ctx.srcSet) || '',
    sizes: (!_ctx.base64Image && _ctx.sizes) || ''
  }, null, 8, _hoisted_1))
}