export enum DateFormat {
    LONG = "long",
    MEDIUM = "medium",
    SHORT = "short",
}

export function formatDate(date: Date | undefined, format: DateFormat = DateFormat.LONG) {
    if (date == undefined) {
        return "";
    }

    switch (format) {
        case DateFormat.LONG:
            // format: November 02, 2022
            return date.toLocaleString("en-US", {
                month: "long",
                day: "2-digit",
                year: "numeric",
            });
        case DateFormat.MEDIUM:
            // format: Nov 02, 2022
            return date.toLocaleString("en-US", {
                month: "short",
                day: "2-digit",
                year: "numeric",
            });
        case DateFormat.SHORT:
            // format: 11/02/2022
            return date.toLocaleString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
            });
        default:
            throw new Error(`Unknown date format: ${format}. Options are ${Object.values(DateFormat).join(", ")}`);
    }
}

export function formateDateTime(date: Date | undefined, format: DateFormat = DateFormat.LONG, hasTime = true) {
    if (date == undefined) {
        return "";
    }

    if (!hasTime) {
        return formatDate(date, format);
    }

    switch (format) {
        case DateFormat.LONG:
            // format: November 02, 2022 12:00 PM
            return date.toLocaleString("en-US", {
                month: "long",
                day: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
            });
        case DateFormat.MEDIUM:
            {
                // format: Nov 02, 2022 12:00 PM
                const formattedDate = date.toLocaleString("en-US", {
                    month: "short",
                    day: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                });

                // remove the comma between year and time
                return formattedDate.substring(0, formattedDate.lastIndexOf(','))
                    + formattedDate.substring(formattedDate.lastIndexOf(',') + 1);
            }
        case DateFormat.SHORT:
            // format: 11/02/2022 12:00 PM
            return date.toLocaleString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
            });
        default:
            throw new Error(`Unknown date format: ${format}. Options are ${Object.values(DateFormat).join(", ")}`);
    }
}

export function getMonthNameFromDate(date: Date) {
    return date.toLocaleString("en-US", {
        month: "long",
    });
}