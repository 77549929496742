import { PaginatedDocs } from '@/types';
import { TypeWithID } from '@/types';
import { Where } from '@/types';
import { Permissions } from '@/types';

import qs from 'qs';

export interface GlobalParams {
    depth?: number
    locale?: string,
    "fallback-locale"?: string,
}

export interface FindParams extends GlobalParams {
    where?: Where,
    sort?: string,
    limit?: number,
    page?: number
    [key: string]: any
}

export interface MeResult<User> {
    user?: Partial<User>,
    collection?: string,
    token?: string,
    exp?: number
}

export class RequestError extends Error {
    constructor(public status: string, public statusCode: number, public data: any, public request: { url: string, options: any }) {
        super(status);
    }
}

export function isRequestError(error: any): error is RequestError {
    return error instanceof RequestError;
}

// the whole reason this exists is because in the plan we decided that we are going to need a mobile app down the line.
// I decided that we needed a stronger foundation/structure up in the data layer so that implementing offline-first functionality
// would be easier when it got time to do that.
export interface DataClient {
    // COLLECTIONS
    // find
    // findById
    // create
    // update
    // delete
    find<T extends TypeWithID>(collection: string, params: FindParams): Promise<PaginatedDocs<T>>,
    findById<T extends TypeWithID>(collection: string, id: string, params: GlobalParams): Promise<T>,
    create<T extends TypeWithID>(collection: string, data: Partial<T>, params: GlobalParams): Promise<T>,
    update<T extends TypeWithID>(collection: string, id: string, data: Partial<T>, params: GlobalParams): Promise<T>,
    delete<T extends TypeWithID>(collection: string, id: string, params: GlobalParams): Promise<T>,

    // TODO: finish auth endpoints
    // AUTH Collections
    // verify
    // unlock
    // login
    // logout
    // refresh-token
    // me
    // forgotPassword
    // resetPassword
    // access
    me<User>(collection: string, params?: GlobalParams): Promise<MeResult<User>>,
    access(): Promise<Permissions>

    // TODO: finish global endpoints
    // Globals
    // findBySlug
    // update

    // TODO: finish preferences
    // Preferences
    // findByKey
    // create/update
    // delete

    // Custom Endpoints
    // collection (GET, POST, PUT, DELETE)
    // global (GET, POST, PUT, DELETE)
    fetchCustomCollectionEndpoint<T>(collection: string, path: string, method: string, params: Record<string, any>): Promise<T>,
    fetchCustomEndpoint<T>(path: string, method: string, params: Record<string, any>): Promise<T>,
}

export class RestClient implements DataClient {
    public baseUrl: string;
    public onError?: (err: unknown) => void;
    public accessToken: string | null;

    constructor(baseUrl = '/', onError?: (err: unknown) => void) {
        this.baseUrl = baseUrl;
        this.onError = onError;
        this.accessToken = localStorage.getItem('payload-token');
    }

    public async request<T>(url: string, options: RequestInit = {}): Promise<T> {
        try {
            // override and or set credentials to include
            options.credentials = 'include';
            this.accessToken = localStorage.getItem('payload-token');

            // include JWT token if it exists
            if (this.accessToken) {
                options.headers = {
                    ...(options.headers && options.headers),
                    'Authorization': `JWT ${this.accessToken}`,
                }
            }

            const response = await fetch(url, options);
            if (!response.ok) {
                throw new RequestError(response.statusText, response.status, await response.json(), { url, options });
            }
            return response.json();
        } catch (err) {
            if (this.onError) {
                this.onError(err);
            }

            throw err;
        }
    }

    public async fetchImage(url: string, options: RequestInit = {}): Promise<Blob> {
        try {
            // override and or set credentials to include
            options.credentials = 'include';
            this.accessToken = localStorage.getItem('payload-token');

            // include JWT token if it exists
            if (this.accessToken) {
                options.headers = {
                    'Authorization': `JWT ${this.accessToken}`,
                }
            }

            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error('Problem fetching image');
            }
            return response.blob();
        } catch (err) {
            if (this.onError) {
                this.onError(err);
            }
            
            throw err;
        }
    }

    public find<T extends TypeWithID = any>(collectionSlug: string, options: FindParams = {}): Promise<PaginatedDocs<T>> {
        const params = qs.stringify(options, { addQueryPrefix: true });
        const url = `${this.baseUrl}/${collectionSlug}${params}`;
        return this.request<PaginatedDocs<T>>(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    public findById<T extends TypeWithID = any>(collectionSlug: string, id: string, options: GlobalParams = {}): Promise<T> {
        const params = qs.stringify(options, { addQueryPrefix: true });
        const url = `${this.baseUrl}/${collectionSlug}/${id}${params}`;
        return this.request<T>(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    public create<T extends TypeWithID = any>(collectionSlug: string, data: Partial<T>, options: GlobalParams = {}): Promise<T> {
        // TODO: add support for file uploads https://payloadcms.com/docs/upload/overview#uploading-files
        const params = qs.stringify(options, { addQueryPrefix: true });
        const url = `${this.baseUrl}/${collectionSlug}${params}`;
        return this.request<T>(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
    }

    public update<T extends TypeWithID = any>(collectionSlug: string, id: string, data: Partial<T>, options: GlobalParams = {}): Promise<T> {
        const params = qs.stringify(options, { addQueryPrefix: true });
        const url = `${this.baseUrl}/${collectionSlug}/${id}${params}`;
        return this.request<T>(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
    }

    public async delete<T extends TypeWithID = any>(collectionSlug: string, id: string, options: GlobalParams = {}): Promise<T> {
        const params = qs.stringify(options, { addQueryPrefix: true });
        const url = `${this.baseUrl}/${collectionSlug}/${id}${params}`;
        return await this.request<T>(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    public access(): Promise<Permissions> {
        const url = `${this.baseUrl}/access`;
        return this.request<Permissions>(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    public me<User>(collection: string, options: GlobalParams = {}): Promise<MeResult<User>> {
        const params = qs.stringify(options, { addQueryPrefix: true });
        const url = `${this.baseUrl}/${collection}/me${params}`;
        return this.request<MeResult<User>>(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    public fetchCustomCollectionEndpoint<T>(collection: string, path: string, method: string, params: Record<string, any> = {}): Promise<T> {
        const queryString = qs.stringify(params, { addQueryPrefix: true });
        const url = `${this.baseUrl}/${collection}/${path}${queryString}`;
        return this.request<T>(url, {
            method,
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    fetchCustomEndpoint<T>(): Promise<T> {
        // TODO: implement
        throw new Error('Method not implemented.');
    }
}

//? not sure if this should go somewhere else or be implemented differently, but this works for now
export const dataClientSingleton = new RestClient(process.env.VUE_APP_API_BASE_URL);

// If making mobile app with offline data do this
// TODO: make offline data client
// TODO: make merged data client (offline + rest, but really a general data client for merging multiple data clients)
