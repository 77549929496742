
  import { Component, defineComponent } from "vue";
  import FileDownload from "@/components/content/FileDownload.vue";
  import SrcsetImage from "@/components/SrcsetImage.vue";
  import { RichTextContent } from "@/types";

  export default defineComponent({
    name: "rich-text-node",
    props: {
      node: {
        type: Object as () => RichTextContent,
        required: true,
      },
    },
    setup(props) {
      function isLeaf(node: any): boolean {
        return node.text != undefined;
      }

      function hasChildren(node: any): boolean {
        return node.children != undefined && node.children.length > 0;
      }

      function isContainerNode(node: any): boolean {
        return !isLeaf(node) && !node.type;
      }

      // mapping nodes like this prevents script injection by only allowing nodes of specific types
      function mapNodeTypeToComponent(node: any): {
        component?: string | Component;
        attributes?: Record<string, unknown>;
        text?: string;
      } {
        if (!node || (node.text === undefined && !node.type)) {
          return {};
        }
        if (isLeaf(node)) {
          if (node.bold) {
            return {
              component: "strong",
              attributes: {},
              text: node.text,
            };
          }

          if (node.strikethrough) {
            return {
              component: "s",
              attributes: {},
              text: node.text,
            };
          }

          if (node.italic) {
            return {
              component: "em",
              attributes: {},
              text: node.text,
            };
          }

          if (node.underline) {
            return {
              component: "u",
              attributes: {},
              text: node.text,
            };
          }

          if (node.code) {
            return {
              component: "code",
              attributes: {},
              text: node.text,
            };
          }

          return {
            component: "span",
            attributes: {},
            text: node.text,
          };
        }

        switch (node.type) {
          case "p":
            return {
              component: "p",
              attributes: {},
            };
          case "h1":
            return {
              component: "h1",
              attributes: {},
            };
          case "h2":
            return {
              component: "h2",
              attributes: {},
            };
          case "h3":
            return {
              component: "h3",
              attributes: {},
            };
          case "h4":
            return {
              component: "h4",
              attributes: {},
            };
          case "h5":
            return {
              component: "h5",
              attributes: {},
            };
          case "h6":
            return {
              component: "h6",
              attributes: {},
            };
          case "quote":
            return {
              component: "blockquote",
              attributes: {},
            };
          case "ul":
            return {
              component: "ul",
              attributes: {},
            };
          case "ol":
            return {
              component: "ol",
              attributes: {},
            };
          case "li":
            return {
              component: "li",
              attributes: {},
            };
          case "link":
            return {
              component: "a",
              attributes: {
                href: node.url,
                target: node.newTab ? "_blank" : "_self",
                "data-link-type": node.linkType,
              },
            };
          case "upload":
            if (
              typeof node.value.mimeType === "string" &&
              node.value.mimeType.startsWith("image")
            ) {
              return {
                component: SrcsetImage,
                attributes: {
                  image: node.value,
                  includeBaseUrl: false,
                  sizeFilter: ([key]: [string]) => !key.endsWith("169"),
                },
              };
            } else {
              return {
                component: FileDownload,
                attributes: {
                  url: node.value.url,
                  filename: node.value.filename,
                },
              };
            }
          case "relationship":
            return {
              component: "span",
              attributes: {},
              text: "",
            };
          default:
            throw new Error(`Unsupported rich text type: ${node.type}`);
        }
      }

      const mappedNode = mapNodeTypeToComponent(props.node);
      const is = mappedNode.component;
      const attributes = mappedNode.attributes;
      const text = mappedNode.text;

      return {
        mapNodeTypeToComponent,
        isContainerNode,
        hasChildren,
        is,
        attributes,
        text,
      };
    },
  });
