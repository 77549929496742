import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.url,
    download: "",
    onClick: _cache[0] || (_cache[0] = ($event: any) => {
      $event.preventDefault();
      _ctx.downloadResource(_ctx.url, _ctx.filename);
    })
  }, _toDisplayString(_ctx.filename ?? _ctx.url), 9, _hoisted_1))
}