import { dataClientSingleton, FindParams } from '@/data/client';
import { News } from '@/payload-types';
import { Where } from '@/types';
import { Ref } from 'vue';
import useAsyncLazyLoad from './useAsyncLazyLoad';

type UseNewsDataOptions = {
	filter: Ref<Where> | undefined,
	limit?: number,
	sort?: string,
};

export function useNewsData(options: UseNewsDataOptions) {
	// fetch article data with where query and pagination
	const { filter, limit, sort } = options;

	const {
		state: articles,
		loadMore: loadMoreArticles,
		hasNextPage: hasMoreArticles,
	} = useAsyncLazyLoad<News, Where>({
		fetch: (page: number, where) => {
			const options: FindParams = {
				page,
				limit: limit ?? 10,
				...(sort && { sort }),
			};

			if (where && Object.keys(where).length > 0) {
				options["where"] = where;
			}

			return dataClientSingleton.find<News>("news", options);
		},
		fetchOptions: filter,
		reloadOnOptionsChange: true,
	});

	return {
		articles,
		loadMoreArticles,
		hasMoreArticles
	}
}