
  export default {
    props: {
      colorFirst: {
        type: Boolean,
        default: true,
      },
      boldHighlight: {
        type: Boolean,
        default: false,
      },
    },
  };
