
  import { defineComponent } from "vue";
  import { formateDateTime, DateFormat } from "@/helpers/fields/date";

  export default defineComponent({
    props: {
      date: {
        type: Date,
      },
      format: {
        type: String as () => DateFormat,
        default: DateFormat.LONG
      },
      hasTime: {
        type: Boolean,
        default: true,
      },
    },
    setup() {
      return { formateDateTime };
    },
  });
