import { dataClientSingleton } from "@/data/client";
import { UseAsyncStateOptions } from "@vueuse/core";
import { MaybeRef } from "@vueuse/core";
import { MaybeComputedRef, useAsyncState } from "@vueuse/core";
import { Ref } from "vue";

function isRef(ref: MaybeComputedRef<any>): ref is Ref<any> {
    return typeof ref === 'object' && 'value' in ref;
}

export function usePayloadFetch<T>(url: MaybeRef<string>, fetchOptions: RequestInit = {}, asyncOptions: UseAsyncStateOptions<any> = {}, initialValue: T | undefined = undefined) {
    const results = useAsyncState<T | undefined>(
        async () => {
            let _url: string | undefined;

            if (isRef(url)) {
                _url = url.value;
            } else if (typeof url === 'string') {
                _url = url;
            }

            if (!_url) {
                return undefined;
            }

            return dataClientSingleton.request(_url, fetchOptions)
        },
        initialValue,
        asyncOptions
    );


    return {
        ...results
    }
}