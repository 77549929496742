const gtm: any = {
    init: () => {
        if (document.getElementById('gtm-js')) {
            return;
        }

        (function (w: any, d: any, l: string, i: string) {
            w[l] = w[l] || []; w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'}); 
            const head = document.getElementsByTagName('head')[0];
            const j = d.createElement('script');
            const dl = l != 'dataLayer' ? '&l=' + l : '';
            j.id = 'gtm-js'
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            head.appendChild(j);
          })(window, document, 'dataLayer', 'GTM-56D9Z86');

          const tag = document.createElement('noscript');
          const iframe = document.createElement('iframe');
          iframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-56D9Z86';
          iframe.style.display = 'none';
          iframe.style.visibility = 'hidden';
          iframe.setAttribute('height', '0');
          iframe.setAttribute('width', '0');
          tag.prepend(iframe);
          document.body.prepend(tag);
    }
}

export default gtm;