import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';
import { isPlatform } from '@ionic/vue';

const isIos = isPlatform('ios');
let status: string;

export async function checkTransparencyStatus(): Promise<void> {
    if (isIos) {
        const response = await AppTrackingTransparency.getStatus();
        status = response.status;
    }

    return Promise.resolve();
}

export async function requestTransparencyPermission(): Promise<void> {
    if (isIos) {
        const response = await AppTrackingTransparency.requestPermission();
        status = response.status;
    }

    return Promise.resolve();
}

export default async function initTransparency(): Promise<void> {
    if (isIos) {
        await checkTransparencyStatus();

        if (status === 'notDetermined') {
            await requestTransparencyPermission();
        }

        if (status !== 'authorized') {
            return Promise.reject();
        }
    }

    return Promise.resolve();
}