import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d91dcc16"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container px-3 pt-4 pb-4-touch" }
const _hoisted_2 = { class: "panes-container is-flex-desktop" }
const _hoisted_3 = { class: "calendar-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_calendar = _resolveComponent("v-calendar")!
  const _component_event_list = _resolveComponent("event-list")!
  const _component_event_single = _resolveComponent("event-single")!
  const _component_pane_container = _resolveComponent("pane-container")!
  const _component_main_layout = _resolveComponent("main-layout")!

  return (_openBlock(), _createBlock(_component_main_layout, null, {
    subtitle: _withCtx(() => [
      _createTextVNode(" Calendar ")
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_v_calendar, {
              ref: "calendar",
              class: "calendar-overrides",
              expanded: true,
              attributes: $setup.eventData.attributes.value,
              onDayclick: $setup.onDayClick,
              "onUpdate:pages": $setup.handlePageEvent,
              "title-position": "left",
              color: "yellow"
            }, null, 8, ["attributes", "onDayclick", "onUpdate:pages"])
          ]),
          _createVNode(_component_pane_container, { class: "list-container" }, {
            default: _withCtx(() => [
              ($setup.selectedEvent == null)
                ? (_openBlock(), _createBlock(_component_event_list, {
                    key: 0,
                    events: $setup.eventData.events.value.docs,
                    "onClick:event": _cache[0] || (_cache[0] = (eventId) => ($setup.selectedEvent = eventId))
                  }, null, 8, ["events"]))
                : (_openBlock(), _createBlock(_component_event_single, {
                    key: 1,
                    id: $setup.selectedEvent,
                    "onClick:back": _cache[1] || (_cache[1] = () => ($setup.selectedEvent = null))
                  }, null, 8, ["id"]))
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}