
import ResourceGroup from './ResourceGroup.vue';

import { dataClientSingleton } from '@/data/client';
import { parseEnvArray, sortCategoriesByLabel } from '@/utilities';
import { useAsyncState } from '@vueuse/core';
import { defineComponent, onMounted } from 'vue';
import { getResourceURL } from '@/helpers/collections/resources';

export default defineComponent({
  components: {
    ResourceGroup,
  },
  props: {
    selectedDepartmentLabel: {
      type: String,
    },
    selectedDepartmentId: {
      type: String,
    },
    resourceType: {
      type: String,
      required: true,
    },
  },
  setup(props: any, { emit }) {
    // fetch resource categories
    const { state: categories, execute: fetchCategories } = useAsyncState(
      () =>
        dataClientSingleton
          .fetchCustomCollectionEndpoint<{ label: string; value: string }[]>(
            props.resourceType,
            `fields/category/options`,
            'GET',
            {},
          )
          .then((res) => {
            const customDocumentsOrder = parseEnvArray(
              'VUE_APP_DOCUMENTS_COLLECTIONS_ORDER',
            );
            const sortedCategories = sortCategoriesByLabel(
              res,
              customDocumentsOrder,
            );
            return sortedCategories;
          }),
      [],
      {
        immediate: false,
      },
    );

    const emitClickEvent = () => {
      emit('click');
    };

    onMounted(async () => {
      await fetchCategories();
    });

    return { categories, getResourceURL, emitClickEvent };
  },
});
