import { RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHistory } from '@ionic/vue-router';

import EventsArchive from '@/pages/events/EventsArchive.vue'
import HomePage from '@/pages/HomePage.vue'
import NewsArchive from '@/pages/news/NewsArchive.vue'
import NewsSingle from '@/pages/news/NewsSingle.vue'
import ResourcesArchive from '@/pages/ResourcesArchive.vue'
import SearchResults from '@/pages/search/SearchResults.vue'
import authorizedGuard from '@/router/hooks/authenticatedGuard';
import searchGuard from '@/router/hooks/searchGuard';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    beforeEnter: authorizedGuard,
  },
  {
    path: '/news',
    name: 'News',
    component: NewsArchive,
    beforeEnter: authorizedGuard,
  },
  {
    path: '/news/:id',
    component: NewsSingle,
    beforeEnter: authorizedGuard,
  },
  {
    path: '/resources',
    name: 'Resources',
    component: ResourcesArchive,
    beforeEnter: authorizedGuard,
  },
  {
    path: '/events',
    name: 'Events',
    component: EventsArchive,
    beforeEnter: authorizedGuard,
  },
  {
    path: '/search',
    name: 'search',
    component: SearchResults,
    beforeEnter: [authorizedGuard, searchGuard],
  },
  {
    path: '/error',
    component: () => import('@/pages/ErrorPage.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/error?code=404',
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
