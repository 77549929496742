import { useEventData } from "@/composables/useEventData";
import { computed, onMounted, watch } from "vue";

export function useDateSelectHack(eventDataParam: ReturnType<typeof useEventData>) {
	const eventData = eventDataParam;

	const dayContainerCssTarget = computed(() => {
		const date = eventData.selectedDate.value;

		// formate date like 2023-01-01 with leading zeros
		const formattedDate = `${date.getFullYear()}-${String(
			date.getMonth() + 1
		).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

		return `.vc-day.id-${formattedDate} .vc-day-content`;
	});

	function updateSelection(newSelected: string, oldSelector: string, retries = 0) {
		const newDayContainer = document.querySelector(
			newSelected
		) as HTMLElement | null;

		if (newDayContainer === null) {
			if (retries < 10) {
				setTimeout(() => {
					updateSelection(newSelected, oldSelector, retries + 1);
				}, 200);
			} else {
				console.error("Couldn't find day container");
			}
		}

		if (oldSelector) {
			const oldDayContainer = document.querySelector(
				oldSelector
			) as HTMLElement | null;
			if (oldDayContainer) {
				oldDayContainer.style.backgroundColor = "transparent";
			}
		}

		if (newDayContainer) {
			newDayContainer.style.backgroundColor = "var(--yellow-600)";
		}
	}

	// set background color hack. Shouldn't have to do this but couldn't find another way...
	watch(dayContainerCssTarget, (newSelected, oldSelector) => updateSelection(newSelected, oldSelector));

	onMounted(() => {
		updateSelection(dayContainerCssTarget.value, "");
	});

	return {
		dayContainerCssTarget,
		updateSelection
	}
}