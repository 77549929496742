
  import MainLayout from "@/components/layouts/MainLayout.vue";
  import PaneContainer from "@/components/PaneContainer.vue";
  import EventList from "@/pages/events/EventsList.vue";
  import EventSingle from "@/pages/events/EventsSingle.vue";

  import { useWindowSize } from "@vueuse/core";
  import { useEventData } from "@/composables/useEventData";
  import { useDateSelectHack } from "@/composables/useDateSelectHack";
  import { ref } from "vue";

  export default {
    components: {
      MainLayout,
      PaneContainer,
      EventList,
      EventSingle,
    },
    setup() {
      const { width: windowWidth } = useWindowSize();

      const eventData = useEventData();

      const selectedEvent = ref<string | null>(null);

      function onDayClick(event: any) {
        if (selectedEvent.value) selectedEvent.value = null;
        eventData.selectedDate.value = event.date;
      }

      // THIS IS A HACK TO DISPLAY THE SELECTED DATE ON THE CALENDAR
      useDateSelectHack(eventData);

      function handlePageEvent(event: any) {
        eventData.selectedYear.value = event[0].year;
        eventData.selectedMonth.value = event[0].month;
      }

      return {
        windowWidth,
        eventData,
        selectedEvent,
        onDayClick,
        handlePageEvent
      };
    },
  };
