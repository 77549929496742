
  import { IonPopover } from "@ionic/vue";
  import { ref } from "vue";

  export default {
    components: {
      IonPopover,
    },
    setup() {
      const isOpen = ref(false);
      const openEvent = ref<MouseEvent>();

      const toggle = (event: MouseEvent) => {
        isOpen.value = !isOpen.value;
        openEvent.value = event;
      };

      return {
        isOpen,
        openEvent,
        toggle,
      };
    },
  };
