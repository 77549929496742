
  import { computed, defineComponent } from "vue";

  export default defineComponent({
    props: {
      items: {
        type: Array,
        required: true,
      },
      columns: {
        type: Number,
        required: true,
        default: 4,
      },
      getEstimatedHeight: {
        type: Function,
        default: () => {
          return 1;
        },
      },
      balanceTolerance: {
        // as a percent of the average height
        type: Number,
        default: 0.6,
      }
    },
    setup(props) {
      const columnItems = computed(() => {
        const buckets: any[] = Array.from({ length: props.columns }, () => []);
        const bucketEstimates: number[] = Array.from(
          { length: props.columns },
          () => 0
        );

        const averageHeight =
          props.items.reduce((acc: number, item) => {
            return acc + props.getEstimatedHeight(item);
          }, 0) / props.items.length;

        let currentBucketIndex = 0;
        for (let i = 0; i < props.items.length; i++) {
          const item = props.items[i];
          const estimatedHeight = props.getEstimatedHeight(item);

          buckets[currentBucketIndex].push(item);
          bucketEstimates[currentBucketIndex] += estimatedHeight;

          // if current bucket is within 1/2 average height of the tallest bucket, move to the next bucket
          const maxBucketHeight = Math.max(...bucketEstimates);
          
          if (
            bucketEstimates[currentBucketIndex] >
            maxBucketHeight - averageHeight * props.balanceTolerance
          ) {
            currentBucketIndex = (currentBucketIndex + 1) % props.columns;
          }
        }

        return buckets;
      });
      return { columnItems };
    },
  });
