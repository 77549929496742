
  type Size = {
    url?: string;
    width?: number;
    height?: number;
    mimeType?: string;
    filesize?: number;
    filename?: string;
  };
  type FilterFunction = (value: [string, Size]) => boolean;

  import { dataClientSingleton } from "@/data/client";
  import { computed, defineComponent, onMounted, ref } from "vue";
  import { getSourceSet, getSourceSizes } from "@/helpers/fields/upload";
  import { Upload } from "@/payload-types";
  import { isPlatform } from '@ionic/vue';

  const defaultSizeFilter: FilterFunction = ([key]) => {
    return key.endsWith("169");
  };

  export default defineComponent({
    props: {
      image: {
        type: Object,
        required: true,
      },
      includeBaseUrl: {
        type: Boolean,
        default: true,
      },
      alt: {
        type: String,
        default: "",
      },
      sizeFilter: {
        type: Function as any as () => FilterFunction,
        // because before this we were only using 16:9 images before this update, we're defaulting to that
        // all 16:9 images will have a width of 169
        default: defaultSizeFilter,
      },
    },
    setup(props) {
      const base64Image = ref("");

      onMounted(() => {
        if (!base64Image.value && isPlatform('ios')) {
          dataClientSingleton.fetchImage(props.image.url)
            .then(blob => {
              return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
              });
            })
            .then(res => {
              base64Image.value = res as string;
            })
            .catch(err => {
              console.error(err);
            });
        }
      });

      const computedImage = computed(() => {
        const image = { ...props.image } as Upload;

        if (props.sizeFilter && !!props.image) {
          image.sizes = Object.entries(image.sizes)
            .filter(props.sizeFilter)
            .reduce((acc, [key, value]) => {
              acc[key as keyof Upload["sizes"]] = value;
              return acc;
            }, {} as Upload["sizes"]);
        }

        return image;
      });

      const fallbackUrl = computed(() => {
        return props.includeBaseUrl
          ? props.image.url
          : Object.values((props.image as Upload)?.sizes ?? {}).reduce(
              (acc, size) => {
                if (size.width === undefined || size.url === undefined) {
                  return acc;
                }

                if (size.width > (acc.width as any)) {
                  acc = size;
                }

                return acc;
              },
              { width: 0, url: "" }
            ).url;
      });

      const srcSet = computed(() => {
        return getSourceSet(computedImage.value, props.includeBaseUrl);
      });

      const sizes = computed(() => {
        return getSourceSizes(computedImage.value, props.includeBaseUrl);
      });

      return { base64Image, getSourceSet, fallbackUrl, computedImage, srcSet, sizes };
    },
  });
