import { Event } from "src/payload-types";

export type MappedEvent = Event & {
	start: Date;
	end: Date;
};

export function mapEvents(dates: Event[]): MappedEvent[] {
	return dates.map((event) => {
		return {
			...event,
			start: new Date(event.start ?? ""),
			end: new Date(event.end ?? ""),
		};
		//? NOT SURE HOW TO TYPE THIS RIGHT NOW BUT DON'T HAVE TIME TO WORRY ABOUT IT
	}) as MappedEvent[];
}


export const isAllDayOrMultiDayEvent = (event: { start: Date; end: Date }) =>
	(event.end.getTime() - event.start.getTime()) % 86400000 === 0; // if an event has a duration that is an exact multiple of 24 hours, it is an all day event.

export const isAllDayEvent = (event: { start: Date; end: Date }) =>
	isAllDayOrMultiDayEvent(event) &&
	event.start.getUTCDate() === event.end.getUTCDate();

export const isMultiDayEvent = (event: { start: Date; end: Date }) =>
	(event.end.getTime() - event.start.getTime() > 86400000)
	&& !isAllDayEvent(event);

export const isMultiMonthEvent = (event: { start: Date; end: Date }) =>
	isMultiDayEvent(event)
	&& event.start.getUTCMonth() !== event.end.getUTCMonth()

export const isMultiYearEvent = (event: { start: Date; end: Date }) =>
	isMultiDayEvent(event)
	&& event.start.getFullYear() !== event.end.getFullYear()

export const getDayText = (date: Date, utc = false) =>
	(utc ? date.getUTCDate() : date.getDate())
		.toString()
		.padStart(2, "0");

// get duration time in AM/PM
export function getTimeDurationText(start: Date, end: Date) {
	const startHours = start.getHours();
	const startMinutes = start.getMinutes();
	const endHours = end.getHours();
	const endMinutes = end.getMinutes();

	const formattedStartHours = startHours % 12 || 12;
	const startHoursString =
		formattedStartHours < 10
			? `0${formattedStartHours}`
			: `${formattedStartHours}`;
	const startMinutesString =
		startMinutes < 10 ? `0${startMinutes}` : `${startMinutes}`;

	const formattedEndHours = endHours % 12 || 12;
	const endHoursString =
		formattedEndHours < 10
			? `0${formattedEndHours}`
			: `${formattedEndHours}`;
	const endMinutesString =
		endMinutes < 10 ? `0${endMinutes}` : `${endMinutes}`;

	const startAMPM = startHours >= 12 ? "PM" : "AM";
	const endAMPM = endHours >= 12 ? "PM" : "AM";

	return `${startHoursString}:${startMinutesString} ${startAMPM} - ${endHoursString}:${endMinutesString} ${endAMPM}`;
}

export const getMonthNameText = (date: Date, utc = false) =>
	[
		"JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP",
		"OCT", "NOV", "DEC"
	][utc ? date.getUTCMonth() : date.getMonth()];


export const getDayOfWeek = (date: Date, utc = false) =>
	[
		"Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday",
		"Saturday"
	][utc ? date.getUTCDay() : date.getDay()];


export function formatEvent(event: { start: Date; end: Date }) {
	const multiDay = isMultiDayEvent(event);
	const multiMonth = isMultiMonthEvent(event);
	const multiYear = isMultiYearEvent(event);
	const allDayOrMulti = isAllDayOrMultiDayEvent(event);

	// use UTC dates for all day and multi day events because they all start at 00:00:00 UTC.
	// use local dates for all other events because we don't want to have an edge
	// case where concerting a date with actual times to UTC will change the day of the event.
	return {
		timeDuration: (allDayOrMulti || multiDay)
			? 'All Day'
			: getTimeDurationText(event.start, event.end),
		dayText: (multiDay)
			? getDayText(event.start, allDayOrMulti) + ' - ' + getDayText(event.end, allDayOrMulti)
			: getDayText(event.start, allDayOrMulti),
		monthText: (multiMonth)
			? getMonthNameText(event.start, true) + ' - ' + getMonthNameText(event.end, true)
			: getMonthNameText(event.start, allDayOrMulti),
		yearText: (multiYear)
			? event.start.getUTCFullYear() + ' - ' + event.end.getUTCFullYear()
			: (allDayOrMulti) ? event.start.getUTCFullYear() : event.start.getFullYear(),
		dayOfWeek: (multiDay)
			? getDayOfWeek(event.start, true) + ' - ' + getDayOfWeek(event.end, true)
			: getDayOfWeek(event.start, allDayOrMulti),
	}
}