import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17a03bac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "resource-archive-container mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_resource_tab = _resolveComponent("resource-tab")!
  const _component_tabbed_panes = _resolveComponent("tabbed-panes")!
  const _component_main_layout = _resolveComponent("main-layout")!

  return (_openBlock(), _createBlock(_component_main_layout, null, {
    subtitle: _withCtx(() => [
      _createTextVNode(" Resources ")
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_tabbed_panes, {
          modelValue: $setup.currentTabIndex,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.currentTabIndex) = $event)),
          tabs: $setup.tabs
        }, {
          documents: _withCtx(() => [
            _createVNode(_component_resource_tab, {
              "resource-type": "documents",
              selectedDepartmentLabel: $setup.selectedDepartmentLabel,
              selectedDepartmentId: $setup.selectedDepartmentId
            }, null, 8, ["selectedDepartmentLabel", "selectedDepartmentId"])
          ]),
          forms: _withCtx(() => [
            _createVNode(_component_resource_tab, {
              "resource-type": "forms",
              selectedDepartmentLabel: $setup.selectedDepartmentLabel,
              selectedDepartmentId: $setup.selectedDepartmentId
            }, null, 8, ["selectedDepartmentLabel", "selectedDepartmentId"])
          ]),
          software: _withCtx(() => [
            _createVNode(_component_resource_tab, {
              "resource-type": "software",
              selectedDepartmentLabel: $setup.selectedDepartmentLabel,
              selectedDepartmentId: $setup.selectedDepartmentId
            }, null, 8, ["selectedDepartmentLabel", "selectedDepartmentId"])
          ]),
          _: 1
        }, 8, ["modelValue", "tabs"])
      ])
    ]),
    _: 1
  }))
}