
  import { computed, defineComponent } from "vue";
  import { useUserStore } from "@/stores/userStore";
  import { User } from "@/payload-types";

  export default defineComponent({
    props: {
      user: {
        type: Object as () => Partial<User>,
      },
    },
    setup(props) {
      const userStore = useUserStore();

      const currentOrPassedUser = computed(() => props.user ?? userStore.user);

      function getUserInitials(user: Partial<User> | null) {
        if (user === null) {
          return "";
        }

        return (user.givenName?.charAt(0) ?? "") + (user.surname?.charAt(0) ?? "");
      }

      return {
        userStore,
        getUserInitials,
        currentOrPassedUser,
      };
    },
  });
