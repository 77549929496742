import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f12d12a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tab-navigation" }
const _hoisted_2 = { class: "tabs container" }
const _hoisted_3 = { class: "tabs-lists my-0" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "icon-container"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "has-text-weight-bold is-size-5-desktop is-size-5-touch" }
const _hoisted_8 = { class: "tab-panes" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("ul", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tabs, (tab, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: 'tab-' + tab.id
            }, [
              _createElementVNode("a", {
                class: _normalizeClass([{ active: index === $setup.currentTab }, "tab-link"]),
                onClick: ($event: any) => ($setup.handleTabChange(index))
              }, [
                (tab.iconUrl)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                      _createElementVNode("img", {
                        class: "icon",
                        src: tab.iconUrl,
                        alt: ""
                      }, null, 8, _hoisted_6)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("span", _hoisted_7, _toDisplayString(tab.label), 1)
              ], 10, _hoisted_4)
            ]))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tabs, (tab, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: 'tab-pane-' + tab.id
        }, [
          ($setup.currentTab === index)
            ? _renderSlot(_ctx.$slots, tab.id, { key: 0 }, undefined, true)
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ], 64))
}