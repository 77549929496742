
import {
  IonGrid,
  IonRow,
  IonCol,
  IonHeader,
  IonToolbar,
  IonPopover,
  IonList,
  IonItem,
  IonLabel
} from '@ionic/vue';
import RouteLink from '@/components/RouteLink.vue';
import UserAvatar from '../UserAvatar.vue';
import { useUserStore } from '@/stores/userStore';
import { onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router'
import { useSearchStore } from '@/stores/searchStore';

export default {
  props: {
    hideUserAvatar: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonHeader,
    IonToolbar,
    RouteLink,
    IonPopover,
    IonList,
    IonItem,
    IonLabel,
    UserAvatar,
  },
  setup() {
    const router = useRouter()
    const userStore = useUserStore();
    const searchStore = useSearchStore();

    const hasCMSAccess = userStore.usePermission('canAccessAdmin');

    const isUserMenuOpen = ref(false);
    const cmsURL = process.env.VUE_APP_CMS_BASE_URL ?? '';
    const apiURL = process.env.VUE_APP_API_BASE_URL ?? '';

    function navigate(url: string, target = '_self') {
      window.open(url, target);
    }

    const currentPath = ref(window.location.pathname);

    onMounted(() => {
      currentPath.value = window.location.pathname;
    });

    watch(router.currentRoute, () => {
      if(router.currentRoute.value.name !== 'search') searchStore.searchModel = ''
    })

    function onSearch() {
      searchStore.setSearch().then(() => router.push({ name: 'search' }))
    }

    return {
      apiURL,
      cmsURL,
      currentPath,
      hasCMSAccess,
      isUserMenuOpen,
      navigate,
      onSearch,
      searchStore,
      userStore,
    };
  },
};
