import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';

const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');

export default async function initPush() {
    if (!isPushNotificationsAvailable) {
        return;
    }
    
    await PushNotifications.addListener('registration', token => {
        console.info('Registration token: ', token.value);
    });

    await PushNotifications.addListener('registrationError', err => {
        console.error('Registration error: ', err.error);
    });

    await PushNotifications.addListener('pushNotificationReceived', notification => {
        console.log('Push notification received: ', notification);
    });

    await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
        console.log('Push notification action performed', notification.actionId, notification.inputValue);
    });

    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
        console.log('Notifications disabled');
    } else {
        console.log('Notifications enabled');
    }

    await PushNotifications.register();

    // const getDeliveredNotifications = async () => {
    //   const notificationList = await PushNotifications.getDeliveredNotifications();
    //   console.log('delivered notifications', notificationList);
    // }
}