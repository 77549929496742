import { useWindowSize } from "@vueuse/core";
import { computed } from "vue";

export function useBreakpoints() {
	const { width } = useWindowSize();

	const breakpoints = computed(() => {
		return {
			isMobile: width.value < 768,
			isTablet: width.value >= 768,
			isTabletOnly: width.value >= 768 && width.value < 1024,
			isTouch: width.value < 1024,
			isDesktop: width.value >= 1024,
			isDesktopOnly: width.value >= 1024 && width.value < 1216,
			isUntilWidescreen: width.value < 1216,
			isWidescreen: width.value >= 1216,
			isWidescreenOnly: width.value >= 1216 && width.value < 1408,
			isUntilFullhd: width.value < 1408,
			isFullhd: width.value >= 1408
		}
	});

	return {
		breakpoints
	};
}