interface Image {
    url?: string;
    width?: number;
}

interface ImageWithSizes extends Image {
    sizes: {
        [key: string]: Image;
    };
}

function getSourceSet(image: ImageWithSizes, includeBaseURL = true): string {
    if (image === undefined) return '';

    const sources: {url: string, width: number}[] = [];
    if (includeBaseURL && image.url && image.width) {
        sources.push({
            url: image.url,
            width: image.width
        });
    }

    for (const value of Object.values(image?.sizes ?? {})) {
        if (value.url === undefined || value.width === undefined) continue;

        sources.push({
            url: value.url,
            width: value.width
        });
    }

    return sources.map((source) => {
        return `${encodeURI(source.url)} ${source.width}w`;
    }).join(', ');
}

function getSourceSizes(image: ImageWithSizes, includeBaseURL = true): string {
    if (image === undefined) return '';

    const sources: {url: string, width: number}[] = [];
    if (includeBaseURL && image.url && image.width) {
        sources.push({
            url: image.url,
            width: image.width
        });
    }

    for (const value of Object.values(image?.sizes ?? {})) {
        if (value.url === undefined || value.width === undefined) continue;

        sources.push({
            url: value.url,
            width: value.width
        });
    }

    return sources.map((source, i) => {
        return i === sources.length - 1 ? `${source.width}px` : `(max-width:${source.width * 2}px) ${source.width}px`;
    }).join(',\n ');
}

export { getSourceSet, getSourceSizes };