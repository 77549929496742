import { defineStore } from "pinia";
import { ref } from "vue";

export const useSearchStore = defineStore('search', () => {
    const term = ref()
    const searchModel = ref()
    const setSearch = async () => {
        term.value = searchModel.value
        return term
    }
  
    return { term, searchModel, setSearch }
  })