import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

import { useUserStore } from "@/stores/userStore";

async function authorizedGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    // TODO: maybe implement some kind of notion of refresh tokens

    const userStore = useUserStore();

    await userStore.fetchTokenSilently();

    // if user is not authenticated, then redirect to login
    if (!userStore.isAuthenticated) {
        // TODO: maybe redirect to landing page instead
        await userStore.login();
        return;
    }

    next();
}

export default authorizedGuard;