
  import { onMounted, ref, watch } from "vue";
  import { Department } from "@/payload-types";
  import { useUserStore } from "@/stores/userStore";
  import { getResourceURL } from "@/helpers/collections/resources";
  import { useRouter } from "vue-router";

  import MainLayout from "@/components/layouts/MainLayout.vue";
  import TabbedPanes from "@/components/TabbedPanes.vue";
  import ResourceTab from "@/components/resources/ResourceTab.vue";

  export default {
    components: {
      MainLayout,
      TabbedPanes,
      ResourceTab,
    },
    setup() {
      const router = useRouter();
      const userStore = useUserStore();

      // check if user department is string array
      const selectedDepartmentId = ref<string | undefined>(
        (userStore.user?.department?.[0] as Department)?.id ??
          userStore.user?.department?.[0]
      );

      const selectedDepartmentLabel = ref<string | undefined>(
        (userStore.user?.department?.[0] as Department)?.name ?? ""
      );

      // tab definitions
      const tabs = [
        {
          label: "Documents",
          id: "documents",
          iconUrl: "/assets/icon/ico_documents.svg",
        },
        {
          label: "Forms",
          id: "forms",
          iconUrl: "/assets/icon/ico_forms.svg",
        },
        {
          label: "Apps",
          id: "software",
          iconUrl: "/assets/icon/ico_apps.svg",
        },
      ];

      // tabs state
      const currentTabIndex = ref(0);

      function updateTabState() {
        let defaultResourceType: "documents" | "forms" | "software" =
          "documents";

        if (!router.currentRoute.value.query.resourceType) {
          defaultResourceType = "documents";
        } else if (
          router.currentRoute.value.query.resourceType !== "documents" &&
          router.currentRoute.value.query.resourceType !== "forms" &&
          router.currentRoute.value.query.resourceType !== "software"
        ) {
          console.warn("Invalid resource type");
          defaultResourceType = "documents";
        } else {
          defaultResourceType = router.currentRoute.value.query.resourceType as
            | "documents"
            | "forms"
            | "software";
        }

        // set current tab index based on resource type
        currentTabIndex.value = tabs.findIndex(
          (tab) => tab.id === defaultResourceType
        );
      }

      watch(router.currentRoute, () => {
        updateTabState();
      });

      onMounted(() => {
        updateTabState();
      });

      return {
        selectedDepartmentId,
        selectedDepartmentLabel,
        getResourceURL,
        currentTabIndex,
        tabs,
      };
    },
  };
