import { dataClientSingleton, FindParams } from '@/data/client';
import { Search } from '@/payload-types';
import { Where } from '@/types';
import { Ref } from 'vue';
import useAsyncLazyLoad from './useAsyncLazyLoad';

type UseSearchDataOptions = {
	filter: Ref<Where> | undefined,
	limit?: number,
	sort?: string,
	depth?: number
};

export function useSearchData(options: UseSearchDataOptions) {
	// fetch article data with where query and pagination
	const { filter, limit, sort, depth } = options;

	const {
		state: articles,
		loadMore: loadMoreArticles,
		hasNextPage: hasMoreArticles,
		isLoading: areArticlesLoading,
	} = useAsyncLazyLoad<Search, Where>({
		fetch: (page: number, where) => {
			const options: FindParams = {
				page,
				limit: limit ?? 10,
				depth: depth ?? 0,
				...(sort && { sort }),
			};

			if (where && Object.keys(where).length > 0) {
				options["where"] = where;
				console.log( where );
			}


			return dataClientSingleton.find<Search>("search", options);
		},
		fetchOptions: filter,
		reloadOnOptionsChange: true,
	});

	return {
		articles,
		loadMoreArticles,
		hasMoreArticles,
		areArticlesLoading
	}

}
