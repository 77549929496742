
  import { computed } from "vue";
  import { useIonRouter } from "@ionic/vue";
  import { useBrowserLocation } from "@vueuse/core";

  export default {
    props: {
      routerLink: {
        type: String,
        default: "",
      },
      rel: {
        type: String,
        default: ""
      },
      ariaLabel: {
        type: String,
        default: ""
      }
    },
    setup(props: any) {
      // setup router navigation
      const ionRouter = useIonRouter();
      const navigate = (e: Event) => {
        e.preventDefault();
        ionRouter.push(props.routerLink);
      };

      // setup active classes
      const location = useBrowserLocation();
      const isActive = computed(() => {
        if (location.value.pathname === undefined) {
          return false;
        }

        // remove trailing slash
        const strippedPath = location.value.pathname.replace(/\/$/, "");
        const strippedLink = props.routerLink.replace(/\/$/, "");

        return strippedPath === strippedLink;
      });

      const isChildActive = computed(() => {
        if (location.value.pathname === undefined) {
          return false;
        }

        const strippedPath = location.value.pathname.replace(/\/$/, "");
        const strippedLink = props.routerLink.replace(/\/$/, "");

        return strippedPath !== strippedLink && strippedPath.startsWith(strippedLink);
      });

      return {
        navigate,
        isActive,
        isChildActive,
      };
    },
  };
