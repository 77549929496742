import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55922355"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tag-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag, index) => {
      return (_openBlock(), _createElementBlock("span", {
        class: "tag",
        key: 'tag-' + index
      }, " #" + _toDisplayString(tag), 1))
    }), 128))
  ]))
}