
  import { IonPage, IonContent } from "@ionic/vue";

  import TopBar from "@/components/nav/TopBar.vue";
  import BottomBar from "@/components/nav/BottomBar.vue";
  import DesktopFooter from "@/components/nav/DesktopFooter.vue";

  export default {
    components: {
      IonPage,
      IonContent,
      TopBar,
      BottomBar,
      DesktopFooter,
    },
  };
