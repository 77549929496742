
  import { usePayloadFetch } from "@/composables/usePayloadFetch";
  import { useUserStore } from "@/stores/userStore";
  import { FilterOption, PaginatedResponse } from "@/types";
  import { News } from "@/payload-types";
  import { EmptyPaginatedResponse } from "@/utility";
  import { computed, onMounted, ref } from "vue";
  import { useIonRouter } from "@ionic/vue";
  import { useBreakpoints } from "@/composables/useBreakpoints";
  import {
    buildLocationalWhereQuery,
    buildStartAndEndQuery,
    buildStatusQuery,
    getFeaturedImage,
    getTags,
  } from "@/helpers/collections/news";
  import { useNewsData } from "@/composables/useNewsData";
  import { useEventData } from "@/composables/useEventData";

  import MainLayout from "@/components/layouts/MainLayout.vue";
  import IconButton from "@/components/IconButton.vue";
  import PaneContainer from "@/components/PaneContainer.vue";
  import PostItem from "@/components/content/NewsListItem.vue";
  import ColorSplitText from "@/components/ColorSplitText.vue";
  import RouteLink from "@/components/RouteLink.vue";
  import EventList from "@/pages/events/EventsList.vue";
  import ItSupportContent from "@/components/content/ItSupportContent.vue";

  export default {
    components: {
      MainLayout,
      IconButton,
      PaneContainer,
      PostItem,
      ColorSplitText,
      RouteLink,
      EventList,
      ItSupportContent,
    },
    setup: function () {
      onMounted(async () => {
        fetchPostData();
      });

      const userStore = useUserStore();
      const ionRouter = useIonRouter();

      const { breakpoints } = useBreakpoints();

      const { state: posts, execute: fetchPostData } = usePayloadFetch<
        PaginatedResponse<News>
      >(
        `${process.env.VUE_APP_API_BASE_URL}/news`,
        {},
        {
          immediate: false,
        },
        EmptyPaginatedResponse
      );

      function loginHandler(e: any) {
        e.preventDefault();
        userStore.login();
      }

      function logoutHandler(e: any) {
        e.preventDefault();
        userStore.logout();
      }

      // date in Monday, October 19, 2020 format
      function formatDate(date: Date) {
        return date.toLocaleDateString("en-US", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      }

      const locationValue = ref<FilterOption[]>([]);
      if (userStore.user?.location) {
        locationValue.value.push(
          ...userStore.user.location.map((loc) => {
            if (typeof loc === "string") {
              return {
                label: loc,
                value: loc,
              };
            } else {
              return {
                label: loc.label ?? loc.name ?? "",
                value: loc.id,
              };
            }
          })
        );
      }

      // create where query from selected filters
      const articleWhereFilter = computed(() => {
        return {
          and: [
            buildLocationalWhereQuery(
              locationValue.value.map((option) => option.value)
            ),
            buildStatusQuery(false),
            buildStartAndEndQuery(new Date()),
          ],
        };
      });

      const { articles } = useNewsData({
        filter: articleWhereFilter,
        sort: '-startDate',
      });

      const eventData = useEventData({ limit: 5, timespan: -1 });

      function createCalendarAttributes(
        events: { start: Date; end: Date; title: string }[]
      ) {
        return events.map((event) => {
          return {
            dot: true,
            dates: event.start,
          };
        });
      }

      function groupEventsByDate(
        events: { start: Date; end: Date; title: string }[]
      ) {
        return events.sort((a, b) => a.start.getTime() - b.start.getTime());
      }

      function navigate(url: string, target = "_self") {
        window.open(url, target);
      }

      return {
        userStore,
        posts,
        fetchPostData,
        loginHandler,
        logoutHandler,
        formatDate,
        ionRouter,
        breakpoints,
        getTags,
        articles,
        eventData,
        createCalendarAttributes,
        groupEventsByDate,
        navigate,
        getFeaturedImage,
      };
    },
  };
