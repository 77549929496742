
  import { defineComponent } from "vue";

  export default defineComponent({
    props: {
      url: {
        type: String,
        required: true,
      },
      filename: {
        type: String,
        required: false,
      },
    },
    setup() {
      function forceDownload(blob: string, filename: string) {
        const a = document.createElement("a");
        a.download = filename;
        a.href = blob;
        // For Firefox https://stackoverflow.com/a/32226068
        document.body.appendChild(a);
        a.click();
        a.remove();
      }

      function downloadResource(url: string, filename?: string) {
        fetch(url, {
          headers: new Headers({
            Origin: location.origin,
          }),
          mode: "cors",
          credentials: "include",
        })
          .then((response) => {
            if (response.status !== 200) {
              alert("Failed to download resource");
              throw new Error(
                "Failed to download resource from " +
                  url +
                  " with status " +
                  response.status +
                  ""
              );
            }
            return response.blob();
          })
          .then((blob) => {
            if (!filename) {
              const newFileName = url.split("\\").pop()?.split("/").pop();
              if (!newFileName) {
                throw new Error("Could not determine filename");
              }

              filename = newFileName;
            }
            const blobUrl = window.URL.createObjectURL(blob);
            forceDownload(blobUrl, filename);
          })
          .catch((e) => console.error(e));

        return false;
      }

      return { downloadResource };
    },
  });
