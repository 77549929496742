
  import CalendarListItem from "@/components/content/CalendarListItem.vue";
  import { MappedEvent } from "@/helpers/collections/events";

  import { defineComponent } from "vue";

  export default defineComponent({
    components: {
      CalendarListItem,
    },
    props: {
      clickable: {
        type: Boolean,
        default: true,
      },
      events: {
        type: Array as () => MappedEvent[],
        required: true,
      },
    },
    emits: ["click:event"],
    setup(_, { emit }) {
      return { emit };
    },
  });
