import { createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6596dcb7"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "newsroom-content-container",
  ref: "el"
}
const _hoisted_2 = {
  key: 0,
  src: "/assets/linear_loading.gif",
  alt: "",
  style: {"margin":"7px auto -18px","display":"block","width":"fit-content"}
}
const _hoisted_3 = {
  key: 1,
  class: "container mt-8"
}
const _hoisted_4 = { class: "container is-hidden-touch mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_post_item = _resolveComponent("post-item")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_pane_container = _resolveComponent("pane-container")!
  const _component_masonry_grid = _resolveComponent("masonry-grid")!
  const _component_main_layout = _resolveComponent("main-layout")!

  return (_openBlock(), _createBlock(_component_main_layout, null, {
    subtitle: _withCtx(() => [
      _createTextVNode(" Search Results ")
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        ($setup.areArticlesLoading)
          ? (_openBlock(), _createElementBlock("img", _hoisted_2))
          : _createCommentVNode("", true),
        (!$setup.articles.length && !$setup.areArticlesLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, "No search results were found for “" + _toDisplayString($setup.searchStore.term) + "”", 1))
          : _createCommentVNode("", true),
        _createVNode(_component_pane_container, { class: "article-list is-hidden-desktop mt-4" }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.searchArticles, (article) => {
              return (_openBlock(), _createBlock(_component_post_item, {
                class: "ion-padding",
                title: article.title ?? '',
                date: article.startDate ? new Date(article.startDate) : undefined,
                key: article.id,
                tags: [article.doc.relationTo],
                url: article.link,
                searched: ""
              }, {
                excerpt: _withCtx(() => [
                  _createTextVNode(_toDisplayString(article.excerpt), 1)
                ]),
                _: 2
              }, 1032, ["title", "date", "tags", "url"]))
            }), 128)),
            _createVNode(_component_ion_infinite_scroll, {
              onIonInfinite: $setup.handleInfiniteScroll,
              disabled: !$setup.hasMoreArticles
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_infinite_scroll_content)
              ]),
              _: 1
            }, 8, ["onIonInfinite", "disabled"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_4, [
          ($setup.width > 1023)
            ? (_openBlock(), _createBlock(_component_masonry_grid, {
                key: 0,
                items: $setup.searchArticles,
                columns: $setup.columns,
                getEstimatedHeight: $setup.estimateHeight
              }, {
                default: _withCtx((slotProps) => [
                  _createVNode(_component_pane_container, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_post_item, {
                        class: "ion-padding",
                        title: slotProps.item.title,
                        date: slotProps.item.startDate
                ? new Date(slotProps.item.startDate)
                : undefined
                ,
                        tags: [slotProps.item.doc.relationTo],
                        url: slotProps.item.link,
                        "featured-image": slotProps.item.featuredImage,
                        searched: ""
                      }, null, 8, ["title", "date", "tags", "url", "featured-image"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              }, 8, ["items", "columns", "getEstimatedHeight"]))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_infinite_scroll, {
            onIonInfinite: $setup.handleInfiniteScroll,
            disabled: !$setup.hasMoreArticles
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_infinite_scroll_content)
            ]),
            _: 1
          }, 8, ["onIonInfinite", "disabled"])
        ])
      ], 512)
    ]),
    _: 1
  }))
}