
  import {
    MappedEvent,
    formatEvent,
    isAllDayOrMultiDayEvent
  } from "@/helpers/collections/events";
  import { computed, defineComponent } from "vue";

  export default defineComponent({
    props: {
      event: {
        type: Object as () => MappedEvent,
        required: true,
      },
      routerLink: {
        type: String,
      },
    },
    setup(props) {
      const formattedEventDate = computed(() => {
        let event: { start: Date; end: Date } = {
          start: props.event.start,
          end: props.event.end,
        };

        if (isAllDayOrMultiDayEvent(props.event)) {
          const fixedEndDate = new Date(props.event.end);
          fixedEndDate.setDate(fixedEndDate.getDate() - 1);
          event = {
            start: event.start,
            end: fixedEndDate,
          };
        }

        return formatEvent(event);
      });

      return {
        formattedEventDate,
      };
    },
  });
