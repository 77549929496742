
  import { IonFooter, IonToolbar } from "@ionic/vue";
  import RouteLink from "@/components/RouteLink.vue";
  export default {
    components: {
      IonFooter,
      IonToolbar,
      RouteLink,
    },
    setup() {
      return {};
    },
  };
