
import useAsyncLazyLoad from '@/composables/useAsyncLazyLoad';
import { dataClientSingleton } from '@/data/client';
import { Form, Software, Document } from '@/payload-types';
import { Resource, Where } from '@/types';
import { defineComponent, onMounted, ref, watch } from 'vue';
import { getResourceURL } from '@/helpers/collections/resources';

import ResourceCardContent from '../content/ResourceCardContent.vue';
import CardContainer from '../CardContainer.vue';
import CardGroup from '../CardScrollGroup.vue';
import { IonButton } from '@ionic/vue';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

export default defineComponent({
  components: {
    ResourceCardContent,
    CardContainer,
    CardGroup,
    IonButton,
  },
  props: {
    category: {
      type: Object as () => { value: string; label: string },
    },
    resourceType: {
      type: String,
      required: true,
    },
    departmentFilterType: {
      type: String as () => Document['departmentFilterType'],
      required: true,
    },
    sectionTitlePrefix: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const isExpanded = ref(false);

    const { state, loadMore, hasNextPage, isLoading } = useAsyncLazyLoad({
      fetch: async (pageNumber) => {
        let where: Where = {};
        if (props.departmentFilterType === 'global') {
          where = {
            category: {
              equals: props.category?.value,
            },
            departmentFilterType: {
              equals: props.departmentFilterType,
            },
          };
        } else {
          where = {
            department: {
              equals: props.category?.value,
            },
            departmentFilterType: {
              equals: props.departmentFilterType,
            },
          };
        }

        return dataClientSingleton.find<Document | Form | Software>(
          props.resourceType,
          {
            where,
            page: pageNumber,
            limit: 10,
            sort: 'sortOrder',
          },
        );
      },
      immediate: false,
    });

    onMounted(() => {
      loadMore();
    });

    // if expanded, load all resources
    watch(isExpanded, async () => {
      if (!isExpanded.value || !hasNextPage.value || isLoading.value) {
        return;
      }

      while (hasNextPage.value && isExpanded.value) {
        await loadMore();
      }
    });

    function handleNewsTabClick(title: string, url: string) {
      FirebaseAnalytics.logEvent({
        name: 'resource_clicks',
        params: {
          screen_name: 'resourcesarchives',
          screen_class: 'ResourceGroupView',
          resource_title: title,
          clicked_url: url,
        },
      });
    }

    const handleCardClick = (resource: Resource) => {
      if (resource.title)
        handleNewsTabClick(resource.title, getResourceURL(resource) as string);
    };

    return {
      state,
      loadMore,
      hasNextPage,
      isExpanded,
      getResourceURL,
      CardGroup,
      handleCardClick,
    };
  },
});
