import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { useSearchStore } from "@/stores/searchStore";

async function searchGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    const searchStore = useSearchStore();
    if (!searchStore.term) {
        next({ name: 'Home' })
    } else {
        next();
    }
}

export default searchGuard;