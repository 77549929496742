
  import { defineComponent } from "vue";
  import { formatDate, DateFormat } from "@/helpers/fields/date";

  export default defineComponent({
    props: {
      date: {
        type: Date,
      },
      format: {
        type: String as () => DateFormat,
        default: DateFormat.LONG,
      },
    },
    setup() {
      return { formatDate };
    },
  });
