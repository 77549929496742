import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c65522d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "multi-select-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
      return (_openBlock(), _createBlock(_component_ion_button, {
        fill: "outline",
        border: "dark",
        class: _normalizeClass(["filter-button", { active: _ctx.isSelected(option) }]),
        key: option.value,
        onClick: ($event: any) => (_ctx.toggleFilter(option))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(option.label), 1)
        ]),
        _: 2
      }, 1032, ["class", "onClick"]))
    }), 128))
  ]))
}