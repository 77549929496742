import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href", "rel", "aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: _normalizeClass(["router-link", { active: $setup.isActive, 'child-active': $setup.isChildActive }]),
    href: $props.routerLink,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.navigate && $setup.navigate(...args))),
    rel: $props.rel,
    "aria-label": $props.ariaLabel
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}