
import MainLayout from "@/components/layouts/MainLayout.vue";
import PaneContainer from "@/components/PaneContainer.vue";
import PostListItem from "@/components/content/NewsListItem.vue";
import MasonryGrid from "@/components/MasonryGrid.vue";
import { computed, ref } from "vue";
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from "@capacitor/push-notifications";
import { dataClientSingleton } from "@/data/client";
import { Location, News, Search } from "@/payload-types";
import {
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/vue";
import { close } from "ionicons/icons";
import { IonInfiniteScrollCustomEvent } from "@ionic/core";
import { useAsyncState, useWindowSize } from "@vueuse/core";
import { FilterOption } from "@/types";
import { useUserStore } from "@/stores/userStore";
import { useSearchData } from "@/composables/useSearchData";
import { useUrlSearchParams } from "@/composables/useUrlSearchParams";
import { useSearchStore } from "@/stores/searchStore";

export default {
  components: {
    MainLayout,
    PaneContainer,
    PostItem: PostListItem,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    MasonryGrid,
  },
  setup() {
    // setup user store
    const userStore = useUserStore();
    const searchStore = useSearchStore();
    const urlSearchParams = useUrlSearchParams()

    const { hasPermission: hasCreateNewsPermissions } =
      userStore.usePermission("collections.news.create");

    // get location options for filters
    const { state: locationOptions } = useAsyncState(
      dataClientSingleton
        .find<Location>("locations", {
          limit: 100,
        })
        .then((response) =>
          response.docs.map((location) => ({
            label: location.label ?? location.name,
            value: location.id,
          }))
        ),
      []
    );

    // create locations filters selected variable and populate with user data
    const locationValue = ref<FilterOption[]>([]);
    if (userStore.user?.location) {
      locationValue.value.push(
        ...userStore.user.location.map((loc) => {
          if (typeof loc === "string") {
            return {
              label: loc,
              value: loc,
            };
          } else {
            return {
              label: loc.label ?? loc.name ?? "",
              value: loc.id,
            };
          }
        })
      );
    }

    function clearAllFilters() {
      locationValue.value = [];
    }

    function removeLocation(location: FilterOption) {
      locationValue.value = locationValue.value.filter(
        (loc) => loc.value !== location.value
      );
    }
    // create where query from selected filters
    const articleWhereFilter = computed(() => {
    // const terms = searchStore.term.toString().split(' ').join(',')
    const w = {
      and: [
        {
          archiveStatus: {
            not_equals: "archived"
          }
        },
        {
          or: [
            {
              title: {
                like: searchStore.term
              }
            },
            {
              description: {
                like: searchStore.term
              }
            },
            {
              excerpt: {
                like: searchStore.term
              }
            },
            {
              keywords: {
                like: searchStore.term
              }
            }
          ]
        }
      ]
    };
    console.log( JSON.stringify( w ) );
    return w;
});

const { articles, loadMoreArticles, hasMoreArticles, areArticlesLoading } = useSearchData({
  filter: articleWhereFilter,
  limit: 10,
  depth: 3
})

const searchArticles = computed(() =>
  articles?.value.map(article => ({
    ...article,
    link: buildReadMoreLink(article)
  }))
)

function buildReadMoreLink(item: Search) {
  switch (item?.contentType) {
    case 'file':
      // @ts-expect-error quiet url does exist
      return item?.uploads?.url
    case 'link':
      return item?.link
    default:
      return `${item.doc.relationTo}/${item.doc.value}`
  }
}


// event handler for infinite scroll component
function handleInfiniteScroll(ev: IonInfiniteScrollCustomEvent<any>) {
  loadMoreArticles().then(() => {
    ev.target.complete();
  });
}

// calculate columns for grid
const { width } = useWindowSize();
const columns = computed(() => {
  if (width.value > 1213) {
    return 4;
  } else {
    return 3;
  }
});

// estimate height of grid item
function estimateHeight(item: News) {
  // the numbers added here are most arbitrary. the only thing that matters is that each element is relative sized to each other.

  let height = 300;
  if (
    item.featuredImage != undefined &&
    typeof item.featuredImage !== "string"
  ) {
    height += 280;
  }

  height += (item?.title?.length ?? 0) / 2;

  return height;
}

const cmsURL = process.env.VUE_APP_CMS_BASE_URL;

if (Capacitor.isPluginAvailable('PushNotifications')) {
  PushNotifications.removeAllDeliveredNotifications();
}

return {
  articles,
  searchArticles,
  handleInfiniteScroll,
  hasMoreArticles,
  locationOptions,
  locationValue,
  clearAllFilters,
  removeLocation,
  close,
  columns,
  width,
  estimateHeight,
  hasCreateNewsPermissions,
  cmsURL,
  urlSearchParams,
  searchStore,
  areArticlesLoading
};
  },
};
