import { onMounted, reactive, watch } from "vue";
import { useRouter } from "vue-router";

export function useUrlSearchParams() {
	const router = useRouter();
	const params = reactive<Record<string, string>>({});

	function getUrlParams() {
		const urlParams = new URLSearchParams(window.location.search);

		// clear existing params
		for (const key in params) {
			delete params[key];
		}

		for (const [key, value] of urlParams) {
			params[key] = value;
		}
	}

	watch(router.currentRoute, () => {
		getUrlParams()
	});

	onMounted(() => {
		getUrlParams()
	});

	return params;
}