
  import RouteLink from "@/components/RouteLink.vue";
  export default {
    components: {
      RouteLink,
    },
    setup() {
      return {};
    },
  };
