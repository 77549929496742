
  import { useBreakpoints } from "@/composables/useBreakpoints";
  import { defineComponent } from "vue";

  export default defineComponent({
    setup() {
      const { breakpoints } = useBreakpoints();
      return {
        breakpoints,
      };
    },
  });
