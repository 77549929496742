
  import { dataClientSingleton } from "@/data/client";
  import { NewsComment } from "@/payload-types";
  import { Where } from "@/types";
  import { useOffsetPagination } from "@vueuse/core";
  import { defineComponent, onMounted, ref } from "vue";
  import { useUserStore } from "@/stores/userStore";
  import UserAvatar from "./UserAvatar.vue";
  import FormattedDateTime from "./FormattedDateTime.vue";
  import { IonButton, IonItem, IonTextarea, IonIcon } from "@ionic/vue";
  import {
    trashOutline,
    caretBackOutline,
    caretForwardOutline,
  } from "ionicons/icons";
  import { DateFormat } from "@/helpers/fields/date";

  export default defineComponent({
    components: {
      UserAvatar,
      FormattedDateTime,
      IonButton,
      IonItem,
      IonTextarea,
      IonIcon,
    },
    props: {
      postId: {
        type: String,
        required: true,
      },
      collectionSlug: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      // submit ref
      const comment = ref<string>("");
      const userStore = useUserStore();

      const comments = ref<NewsComment[] | null>(null);
      const total = ref<number>(1);
      const pageSize = ref<number>(25);

      function fetchComments({
        currentPage,
        currentPageSize,
      }: {
        currentPage: number;
        currentPageSize: number;
      }) {
        const where: Where = {
          commentedOn: {
            equals: props.postId,
          },
          "commentedOn.disableComments": {
            not_equals: true,
          },
        };

        const result = dataClientSingleton.find<NewsComment>("news-comments", {
          where,
          depth: 1,
          limit: currentPageSize,
          page: currentPage,
        });

        result.then((data) => {
          comments.value = data.docs;
          total.value = data.totalDocs;
        });
      }

      const {
        currentPage,
        currentPageSize,
        pageCount,
        isFirstPage,
        isLastPage,
        prev,
        next,
      } = useOffsetPagination({
        total: total,
        page: 1,
        pageSize: pageSize,
        onPageChange: fetchComments,
        onPageSizeChange: fetchComments,
      });

      const handleFormSubmission = (e: any) => {
        e.preventDefault();

        dataClientSingleton
          .create<NewsComment>(props.collectionSlug, {
            content: comment.value,
            commentedOn: props.postId,
            author: userStore.user?.id,
          })
          .then(() => {
            comment.value = "";
            fetchComments({
              currentPage: currentPage.value,
              currentPageSize: currentPageSize.value,
            });
          })
          .catch(() => {
            comment.value = "";
            alert(
              "There was an error submitting your comment. Please try again."
            );
          });
      };

      const deleteComment = (id: string) => {
        dataClientSingleton
          .delete<NewsComment>(props.collectionSlug, id)
          .then(() => {
            fetchComments({
              currentPage: currentPage.value,
              currentPageSize: currentPageSize.value,
            });
          })
          .catch(() => {
            alert(
              "There was an error deleting your comment. Please try again."
            );
          });
      };

      const commentToHtml = (comment: string) =>
        comment.replace(/\n/g, "<br />");

      function isCommentOwner(comment: NewsComment) {
        if (typeof comment.author === "string") {
          return comment.author === userStore.user?.id;
        }

        return comment.author.id === userStore.user?.id;
      }

      onMounted(() => {
        fetchComments({
          currentPage: currentPage.value,
          currentPageSize: currentPageSize.value,
        });
      });

      return {
        comments,
        comment,
        trashOutline,
        userStore,
        currentPage,
        currentPageSize,
        pageCount,
        isFirstPage,
        isLastPage,
        caretBackOutline,
        caretForwardOutline,
        DateFormat,
        prev,
        next,
        handleFormSubmission,
        commentToHtml,
        deleteComment,
        isCommentOwner,
      };
    },
  });
