import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b2590cca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "color-split-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("span", {
      class: _normalizeClass({ highlighted: $props.colorFirst, bolded: $props.colorFirst && $props.boldHighlight })
    }, [
      ($props.colorFirst)
        ? _renderSlot(_ctx.$slots, "color", { key: 0 }, undefined, true)
        : _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true)
    ], 2),
    _createElementVNode("span", {
      class: _normalizeClass({
        highlighted: !$props.colorFirst,
        bolded: !$props.colorFirst && $props.boldHighlight,
      })
    }, [
      (!$props.colorFirst)
        ? _renderSlot(_ctx.$slots, "color", { key: 0 }, undefined, true)
        : _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true)
    ], 2)
  ]))
}