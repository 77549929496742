import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b1535c60"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top_bar = _resolveComponent("top-bar")!
  const _component_DesktopFooter = _resolveComponent("DesktopFooter")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_bottom_bar = _resolveComponent("bottom-bar")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, {
    id: "main-content",
    class: "main-layout-container"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_top_bar, null, {
        subtitle: _withCtx(() => [
          _renderSlot(_ctx.$slots, "subtitle", {}, () => [
            _createTextVNode("Subtitle")
          ], true)
        ]),
        _: 3
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("main", _hoisted_1, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          _createVNode(_component_DesktopFooter, { class: "is-hidden-touch" })
        ]),
        _: 3
      }),
      _createVNode(_component_bottom_bar, { class: "is-hidden-desktop" })
    ]),
    _: 3
  }))
}