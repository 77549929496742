import { PaginatedResponse } from '@/types';

export const EmptyPaginatedResponse: PaginatedResponse<any> = {
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 0,
    nextPage: null,
    page: 0,
    pagingCounter: 0,
    prevPage: null,
    totalDocs: 0,
    totalPages: 0
};