
  import { defineComponent } from "vue";
  import { getResourceURL } from "@/helpers/collections/resources";
  import { Resource } from "@/types";

  export default defineComponent({
    props: {
      resource: {
        type: Object as () => Resource,
        required: true,
      },
    },
    setup() {
      return {
        getResourceURL,
      };
    },
  });
