import { Resource } from "@/types";

export function getResourceURL(resource: Resource) {
    switch (resource.contentType) {
        case "link":
            return resource.link;
        case "file":
            if (typeof resource.file === "string") {
                throw new Error("Resource file is id, not file object. You may need to increase the depth parameter on your query.");
            }

            return resource.file?.url;
        default:
            throw new Error(`Unknown resource content type: ${resource.contentType}`);
    }
}
