import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-303c65bd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "icon-container" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "text-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.isAnchorTagComputed ? 'a' : 'button'), {
    class: _normalizeClass(["icon-button is-flex is-align-items-center is-justify-content-center", {
      active: _ctx.active,
      large: _ctx.size === 'large',
      'is-flex-direction-row': _ctx.layout === 'row',
      'is-flex-direction-column': _ctx.layout === 'column',
      'layout-row': _ctx.layout === 'row',
      'layout-column': _ctx.layout === 'column',
      'style-outlined': _ctx.type === 'outlined',
      'style-flat': _ctx.type === 'flat',
      'style-transparent': _ctx.type === 'transparent',
    }]),
    onClick: _ctx.handleClick,
    disabled: (!_ctx.isAnchorTagComputed && _ctx.disabled) || undefined,
    href: _ctx.href,
    target: _ctx.target,
    rel: _ctx.rel
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createElementVNode("img", {
          src: _ctx.icon,
          alt: _ctx.alt
        }, null, 8, _hoisted_2)
      ]),
      _createElementVNode("span", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ]),
    _: 3
  }, 8, ["class", "onClick", "disabled", "href", "target", "rel"]))
}