
  import { defineComponent } from "vue";
  import RouteLink from "@/components/RouteLink.vue";

  export default defineComponent({
    components: {
      RouteLink,
    },
    props: {
      routerLink: {
        type: String,
        required: true,
      },
    },
    setup() {
      return {};
    },
  });
