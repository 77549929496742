
  import { dataClientSingleton } from "@/data/client";
  import { News } from "@/payload-types";
  import { useAsyncState } from "@vueuse/core";
  import { useRoute } from "vue-router";
  import { getTags } from "@/helpers/collections/news";
  import { useUrlSearchParams } from "@/composables/useUrlSearchParams";
  import { Where } from "@/types";
  import { onMounted, ref, watch } from "vue";
  import { DateFormat } from "@/helpers/fields/date";

  import MainLayout from "@/components/layouts/MainLayout.vue";
  import RichText from "@/components/content/RichText.vue";
  import TagList from "@/components/content/TagList.vue";
  import FormattedDate from "@/components/FormattedDate.vue";
  import BackBar from "@/components/BackBar.vue";
  import CommentsSection from "@/components/CommentsSection.vue";
  import SrcsetImage from "@/components/SrcsetImage.vue";

  // import FeedbackButtons from "@/components/FeedbackButtons.vue";

  export default {
    components: {
      MainLayout,
      RichText,
      TagList,
      FormattedDate,
      BackBar,
      CommentsSection,
      SrcsetImage,
      // FeedbackButtons,
    },
    setup() {
      // get id from route
      const route = useRoute();
      const id =
        typeof route.params.id === "string"
          ? route.params.id
          : route.params.id[0];

      const articleNotFound = ref(false);
      const urlSearchParams = useUrlSearchParams();
      // fetch article
      const {
        state: article,
        isLoading,
        execute,
      } = useAsyncState(
        (): Promise<News | null> => {
          const where: Where = {
            id: {
              equals: id,
            },
          };

          if (urlSearchParams.preview !== "true") {
            where["_status"] = { equals: "published" };
          }

          return dataClientSingleton
            .find<News>("news", { where })
            .then((res) => (res.docs.length > 0 ? res.docs[0] : null));
        },
        null,
        {
          onSuccess: (article) => {
            if (article === null) {
              articleNotFound.value = true;
            }
          },
          immediate: false,
        }
      );

      onMounted(() => {
        execute();
        watch(urlSearchParams, () => {
          execute();
        });
      });

      return {
        id,
        article,
        isLoading,
        getTags,
        articleNotFound,
        DateFormat,
      };
    },
  };
