
  import { computed, defineComponent } from "vue";

  export default defineComponent({
    props: {
      icon: {
        type: String,
        required: true,
      },
      size: {
        type: String,
        default: "large",
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      active: {
        type: Boolean,
        default: false,
      },
      layout: {
        type: String,
        default: "horizontal",
      },
      type: {
        type: String,
        default: "outlined",
      },
      isAnchorTag: {
        type: Boolean,
        default: false,
      },
      href: {
        type: String,
      },
      target: {
        type: String,
      },
      rel: {
        type: String,
      },
      alt: {
        type: String,
        default: "",
      },
    },
    emits: ["click"],
    setup(props, { emit }) {
      const isAnchorTagComputed = computed(() => {
        if (props.isAnchorTag) {
          return true;
        }

        return !!props.href;
      });

      const handleClick = (e: MouseEvent) => {
        if (!isAnchorTagComputed.value) {
          e.preventDefault();
        } else {
          e.stopPropagation();
        }

        if (!props.disabled) {
          emit("click");
        }
      };



      return {
        handleClick,
        isAnchorTagComputed,
      };
    },
  });
