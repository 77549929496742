
  import { defineComponent } from "vue";
  import { IonButton } from "@ionic/vue";
  import { FilterOption } from "@/types";

  export default defineComponent({
    components: {
      IonButton,
    },
    props: {
      modelValue: {
        type: Array<FilterOption>,
        required: true,
      },
      options: {
        type: Array<FilterOption>,
        default: () => [],
      },
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
      const toggleFilter = function (option: FilterOption) {
        const index = props.modelValue.findIndex(
          (o) => o.value === option.value
        );

        const newOptions = [...props.modelValue];
        if (index > -1) {
          newOptions.splice(index, 1);
        } else {
          newOptions.push(option);
        }

        emit("update:modelValue", newOptions);
      };

      const isSelected = function (option: FilterOption) {
        return props.modelValue.findIndex((o) => o.value === option.value) > -1;
      };

      return { toggleFilter, isSelected };
    },
  });
