import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0416906b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "back-bar pt-2-touch pb-4-touch pb-5-desktop pt-4-desktop" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_route_link = _resolveComponent("route-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_route_link, {
      class: "back-link",
      "router-link": _ctx.routerLink
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "back-symbol", {}, () => [
          _createTextVNode("<")
        ], true),
        _createTextVNode(" "),
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createTextVNode("Back")
        ], true)
      ]),
      _: 3
    }, 8, ["router-link"])
  ]))
}