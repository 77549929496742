
import RouteLink from '@/components/RouteLink.vue';
import TagList from '@/components/content/TagList.vue';
import FormattedDate from '@/components/FormattedDate.vue';
import SrcsetImage from '@/components/SrcsetImage.vue';
import { Upload } from '@/payload-types';
import { getMonthNameFromDate } from '@/helpers/fields/date';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

export default {
  name: 'PostListItem',
  components: {
    RouteLink,
    TagList,
    FormattedDate,
    SrcsetImage,
  },
  props: {
    date: {
      type: Date,
    },
    title: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    tags: {
      type: Array,
    },
    featuredImage: {
      type: Object as () => Upload,
    },
    layout: {
      type: String as () => 'vertical' | 'horizontal',
      default: 'vertical',
      validate: (value: string) => {
        return ['vertical', 'horizontal'].includes(value);
      },
    },
    headerTag: {
      type: String as () => 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6',
      default: 'h2',
      validate: (value: string) => {
        return ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(value);
      },
    },
    searched: {
      type: Boolean
    }
  },
  setup(props: any) {
    function handleNewsItemClick() {
      FirebaseAnalytics.logEvent({
        name: 'news_click',
        params: {
          screen_name: 'newslist',
          screen_class: 'NewsListItem',
          url: props.url,
          title: props.title,
        },
      });
    }
    return {
      getMonthNameFromDate,
      handleNewsItemClick,
    };
  },
};
