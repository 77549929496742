import { dataClientSingleton } from "@/data/client";
import { mapEvents, MappedEvent } from "@/helpers/collections/events";
import { EmptyPaginatedResponse } from "@/utility";
import { PaginatedDocs, Where } from "@/types";
import { onMounted, ref, watch } from "vue";
import { Event } from "@/payload-types";
import { useUserStore } from "../stores/userStore";

type UseEventDataOptions = {
	limit?: number,
	timespan?: number
}

export const useEventData = function ({ limit, timespan = 1000 * 60 * 60 * 24 * 31 }: UseEventDataOptions = { limit: 50, timespan: 1000 * 60 * 60 * 24 * 31 }) {
	const userStore = useUserStore();

	const selectedYear = ref(new Date().getFullYear());
	const selectedDate = ref(new Date());
	const selectedMonth = ref(new Date().getMonth() + 1);

	const attributes = ref<{ dot: boolean, dates: Date | Date[] | string }[]>([]);

	const events = ref<PaginatedDocs<MappedEvent>>(EmptyPaginatedResponse);

	async function fetchEvents() {
		const timeRange: Where = {
			or: [
				// single day events
				{
					start: {
						greater_than_equal: selectedDate.value.getTime(),
					},
				},
				// multi day events
				{
					and: [
						{
							start: {
								less_than_equal: selectedDate.value.getTime(),
							},
						},
						{
							end: {
								greater_than_equal: selectedDate.value.getTime(),
							}
						},
					]
				}
			],
		};

		if (timespan !== undefined && timespan > 0) {
			timeRange.end = {
				less_than_equal: selectedDate.value.getTime() + timespan,
			}
		}

		const data = await dataClientSingleton.find<MappedEvent>("events", {
			limit: limit ?? 50,
			where: {
				and: [
					timeRange,
					{
						or: [
							{
								isCompanyWide: {
									equals: true
								},
							},
							{
								dibLocation: {
									equals: userStore.user?.location?.[0].id
								}
							}
						]
					}
				]

			},
			sort: "start"
		});

		events.value = {
			...data,
			docs: mapEvents(data.docs),
		};
	}

	async function fetchAttributes() {
		const today = new Date();
		const year = today.getFullYear();

		const start = new Date(
			year,
			selectedMonth.value - (1 % 12),
			1
		).getTime();
		const end = new Date(year, selectedMonth.value, 1).getTime();

		const data = await dataClientSingleton.find<Event>("events", {
			limit: limit ?? 50,
			where: {
				and: [
					{
						start: {
							greater_than_equal: start,
						},
						end: {
							less_than: end,
						},
					},
					{
						or: [
							{
								isCompanyWide: {
									equals: true
								},
							},
							{
								dibLocation: {
									equals: userStore.user?.location?.[0].id
								}
							}
						]
					}
				]
			},
		});

		const mappedEvents = {
			...data,
			docs: mapEvents(data.docs),
		};

		attributes.value = mapEventResponseToCalendarAttributes(mappedEvents.docs);
	}

	// data structure to store the little dots on the calendar for when events are happening
	function mapEventResponseToCalendarAttributes(events: MappedEvent[]) {
		// TODO: handle multi day events (each day should get its own dot probably?)

		return events.map((event) => {
			const dates: Date = new Date(event.start);

			// force the date to be at noon so that it doesn't get offset by the timezone
			dates.setUTCHours(12, 0, 0, 0);

			return {
				dot: true,
				dates: dates
			};
		});
	}

	watch(selectedDate, () => {
		fetchEvents();
	});

	watch(selectedMonth, () => {
		fetchAttributes();

		// kind of hacky but selecting data to fast breaks the date select hack
		setTimeout(() => {
			selectedDate.value = new Date(selectedYear.value, selectedMonth.value - 1, 1);
		}, 500)

	})

	onMounted(() => {
		fetchEvents();
		fetchAttributes();
	})

	return {
		selectedDate,
		selectedMonth,
		selectedYear,
		events,
		attributes,
		fetchAttributes,
		fetchEvents
	}
}
