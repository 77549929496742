
  import { onMounted, ref, watch } from "vue";

  export default {
    emits: ["update:modelValue", "change"],
    props: {
      tabs: {
        type: Array,
        required: true,
      },
      modelValue: {
        type: Number,
        default: 0,
      },
    },
    setup(props: any, { emit }: any) {
      const currentTab = ref(props.modelValue);

      function handleTabChange(tabIndex: number) {
        const lastTab = props.tabs[currentTab.value];
        currentTab.value = tabIndex;

        emit("update:modelValue", tabIndex);
        emit("change", {
          from: lastTab,
          current: props.tabs[currentTab.value],
        });
      }

      watch(props, () => {
        currentTab.value = props.modelValue;
      });

      onMounted(() => {
        currentTab.value = props.modelValue;
      });

      return { currentTab, handleTabChange };
    },
  };
