
  import {
    IonApp,
    IonRouterOutlet
  } from "@ionic/vue";
  import { defineComponent } from "vue";
  import initPush from "./services/push";

  initPush();

  export default defineComponent({
    name: "App",
    components: {
      IonApp,
      IonRouterOutlet
    },
  });
