/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
// import '@ionic/vue/css/float-elements.css';
// import '@ionic/vue/css/text-alignment.css';
// import '@ionic/vue/css/text-transformation.css';
// import '@ionic/vue/css/flex-utils.css';
// import '@ionic/vue/css/display.css';

/* Ionic Theme variables */
import './styles/theme/ionic-variables.scss';

/* Vendor SCSS */
import './styles/vendor/bulma.scss';
import './styles/vendor/swiper.scss';

/* Main SCSS */
import './styles/main.scss';

import App from './App.vue'
import { IonicVue } from '@ionic/vue';
import { isPlatform } from '@ionic/vue';
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import router from './router';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

// import v-calendar
import { setupCalendar, Calendar } from 'v-calendar';
import 'v-calendar/style.css';
import { dataClientSingleton, isRequestError } from './data/client';
import initTransparency from "./services/transparency";
import gtm from './services/gtm';

// Initialize Firebase Analytics
const isAnalyticsReady = initTransparency()
  .then(() => {
    // initialize Google Tag Manager
    gtm.init();

    return FirebaseAnalytics.initializeFirebase({
      apiKey: "AIzaSyDryq96m0eDnaLGCD8DqEZ6nL1OA-POLdE",
      authDomain: "my-do-it-best-workbench.firebaseapp.com",
      projectId: "my-do-it-best-workbench",
      storageBucket: "my-do-it-best-workbench.appspot.com",
      messagingSenderId: "435095112902",
      appId: "1:435095112902:web:5168bdd1ab140d28bf5bc7",
      measurementId: "G-7QZP1BM4KK"
    });
  })
  .catch(() => {
    // Do not load Firebae Analytics or Google Tag Manager Script
  });

// setup state management
const pinia = createPinia();

// create vue application
const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(pinia)
  .use(setupCalendar, {});

app.component('VCalendar', Calendar);

dataClientSingleton.onError = ((err) => {
  if (isRequestError(err)) {
    if (err.statusCode === 403) {
      console.log("An error occurred", {
        statusCode: err.statusCode,
        status: err.status,
        message: err.message,
        data: err.data,
        request: err.request
      })
      console.error(err);
      window.location.href = "/error?code=403";
      return;
    }

    if (err.statusCode >= 500 || err.statusCode === 404) {
      console.log("An error occurred", {
        statusCode: err.statusCode,
        status: err.status,
        message: err.message,
        data: err.data,
        request: err.request
      })
      console.error(err);
      window.location.href = `/error?message=${encodeURIComponent('You are experiencing server errors. Please try again later.')}`;
      return;
    }
  }
})

// wait for router and firebase analytics to be ready before mounting app
Promise.allSettled([router.isReady(), isAnalyticsReady]).then(() => {
  const isDesktop = !isPlatform('capacitor');

  // only disable text selection for capacitor platform
  if (isDesktop) {
    const headTag = document.querySelector("head");
    headTag && headTag.insertAdjacentHTML("beforeend", `
      <style>
      html.plt-mobile ion-app{
        -webkit-user-select:text;
        -moz-user-select:text;
        -ms-user-select:text;
        user-select:text;
      }
      </style>
    `);
  }

  router.beforeEach((to, _from, next) => {
    FirebaseAnalytics.logEvent(
      { 
        name: 'screen_view', 
        params: { 
          [isDesktop ? 'firebase_screen' : 'screen_name']: to.name || to.path,
          [isDesktop ? 'firebase_screen_class' : 'screen_class']: 'AppView'
        }
      }
    );
    next();
  });

  app.mount('#app');
});

// no dark theme???
document.querySelector("body")?.classList.remove("dark");