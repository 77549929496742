import { PaginatedDocs } from '@/types';
import { TypeWithID } from "@/types";
import { computed, ComputedRef, onMounted, reactive, Ref, ref, watch } from "vue";

export interface UseAsyncLazyLoadOptions<T extends TypeWithID = any, O = any> {
    fetch: (pageNumber: number, fetchOptions?: O) => Promise<PaginatedDocs<T>>,
    immediate?: boolean,
    fetchOptions?: Ref<O> | ComputedRef<O>,
    reloadOnOptionsChange?: boolean
}


function useAsyncLazyLoad<T extends TypeWithID = any, O = any>(options: UseAsyncLazyLoadOptions<T, O>) {
    if (options.immediate === undefined) {
        options.immediate = true;
    }

    if (options.reloadOnOptionsChange === undefined) {
        options.reloadOnOptionsChange = true;
    }

    const state = reactive<Record<string, T[]>>({});
    const output = computed(() => {
        const keys = Object.keys(state);
        // order keys numerically ascending
        keys.sort((a, b) => parseInt(a) - parseInt(b));
        return keys.map(key => state[key]).flat();
    })

    // const state = ref<T[]>([]);
    const isLoading = ref<boolean>(false);
    let lastLoadedPage = 1;
    const hasNextPage = ref(true);

    async function loadMore(complete?: () => void) {
        if (isLoading.value) {
            return;
        }
        
        if (hasNextPage.value) {
            isLoading.value = true;

            const response = await options.fetch(lastLoadedPage, options.fetchOptions?.value);

            if (!response.page) {
                throw new Error("No page number returned from fetch function");
            }

            state[response.page.toString()] = response.docs;

            // update the max pages
            hasNextPage.value = response.hasNextPage;
            lastLoadedPage++;
            isLoading.value = false
            complete?.();
        } else {
            complete?.();
        }
    }

    async function clear() {
        state.value = [];
        lastLoadedPage = 1;
        hasNextPage.value = true;
    }

    async function reload() {
        await clear();
        await loadMore();
    }

    if (options.fetchOptions != undefined && options.reloadOnOptionsChange) {
        watch(options.fetchOptions, async () => {
            await reload();
        })
    }

    if (options.immediate) {
        onMounted(() => {
            loadMore();
        })
    }

    return {
        state: output,
        hasNextPage,
        loadMore,
        clear,
        reload,
        isLoading
    };
}

export default useAsyncLazyLoad;