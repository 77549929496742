import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-356bb386"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "calendar-list-item-container is-flex py-3" }
const _hoisted_2 = { class: "date-container is-flex is-flex-direction-column is-justify-content-center is-align-items-center px-3" }
const _hoisted_3 = { class: "day-container" }
const _hoisted_4 = { class: "month-year-container" }
const _hoisted_5 = { class: "event-details-container px-3" }
const _hoisted_6 = { class: "is-size-3 is-size-1-desktop" }
const _hoisted_7 = { class: "is-size-5 has-font-weight-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.formattedEventDate.dayText), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.formattedEventDate.monthText) + " " + _toDisplayString(_ctx.formattedEventDate.yearText), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.formattedEventDate.dayOfWeek) + " | " + _toDisplayString(_ctx.formattedEventDate.timeDuration), 1),
      _createElementVNode("h1", _hoisted_7, _toDisplayString(_ctx.event.summary), 1)
    ])
  ]))
}