
  import { defineComponent } from "vue";
  import RichTextNode from "./RichTextNode.vue";
  import { RichTextContent } from "@/types";

  export default defineComponent({
    components: {
      RichTextNode,
    },
    props: {
      content: {
        type: Array as () => RichTextContent[],
      },
    },
    setup() {
      return {};
    },
  });
