
  import { dataClientSingleton } from "@/data/client";
  import { Event } from "@/payload-types";
  import { useAsyncState } from "@vueuse/core";
  import { defineComponent } from "vue";

  export default defineComponent({
    components: {},
    emits: ["click:back"],
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    inheritAttrs: false,
    setup(props, { emit }) {
      const { state: event } = useAsyncState(
        dataClientSingleton.findById<Event>("events", props.id),
        undefined
      );

      function formateDateRange(start: Date, end: Date, isAllDay: boolean) {
        // if on the same day, January 1, 2023 12:00 AM - 11:59 PM
        // if spans multiple days, January 1, 2023 12:00 AM - January 2, 2023 11:59 PM
        // if all day event, January 1, 2023 - January 2, 2023

        const dateFormat: any = {
          month: "long",
          day: "numeric",
          year: "numeric",
        };

        const timeFormat: any = {
          hour: "numeric",
          minute: "numeric",
        };

        const dateTimeFormat: any = {
          ...dateFormat,
          ...timeFormat,
        };

        const startDate = start.toLocaleDateString("en-US", dateFormat);
        const endDate = end.toLocaleDateString("en-US", dateFormat);
        const startDateTime = start.toLocaleTimeString("en-US", dateTimeFormat);
        const endDateTime = end.toLocaleTimeString("en-US", dateTimeFormat);
        const endTime = end.toLocaleTimeString("en-US", timeFormat);

        if (isAllDay) {
          return `${startDate} - ${endDate}`;
        }

        if (start.getDate() !== end.getDate()) {
          return `${startDateTime} - ${endDateTime}`;
        } else {
          return `${startDateTime} - ${endTime}`;
        }
      }

      return { event, emit, formateDateRange };
    },
  });
