import { Department, Location } from "@/payload-types";
import { computed } from "vue";
import { dataClientSingleton as dataClient } from "@/data/client";
import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";
import { Permissions } from "@/types";
import { isPlatform } from '@ionic/vue';
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser";
import router from "@/router/index";
import { FCM } from '@capacitor-community/fcm';
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

export interface UserDepth1 {
    id: string;
    givenName?: string;
    surname?: string;
    email?: string;
    department?: Department[];
    location?: Location[];
    isGoogleAccount?: boolean;
    ntservId?: string;
    actions: {
        action?: string;
        resource?: string;
        modifier?: string;
        id?: string;
    }[];
    nameId?: string;
    samlData?: string;
    createdAt: string;
    updatedAt: string;
}


export const useUserStore = defineStore('user', () => {
    const objectSerializer = {
        read: (value: any) => value ? JSON.parse(value) : null,
        write: (value: any) => JSON.stringify(value)
    };

    const user = useLocalStorage<Partial<UserDepth1> | null>('user', null, {
        serializer: objectSerializer
    });

    const access = useLocalStorage<Permissions | null>('access', null, {
        serializer: objectSerializer
    });

    const token = useLocalStorage<string | null>('payload-token', null);
    const expiration = useLocalStorage<number | null>('payload-token-expiration', null);
    const isAuthenticated = computed(() => !!token.value);

    function clearToken() {
        token.value = null;
        expiration.value = null;
        user.value = null;
        access.value = null;
    }

    function setToken(data: { token: string | null, expiration: number | null, user: Partial<UserDepth1> | null }) {
        token.value = data.token;
        expiration.value = data.expiration;
        user.value = data.user;
    }

    async function fetchTokenSilently() {
        try {
            const meResponse = await dataClient.me<Partial<UserDepth1>>('users')
            const expirationTime = meResponse.exp ?? null;
            const tokenExpired = expirationTime ? expirationTime < new Date().getTime() : false;

            // if response comes back with no token, then user is not logged in
            if (meResponse.token == undefined || !meResponse.user || tokenExpired) {
                clearToken();
            } else {
                setToken({
                    token: meResponse.token,
                    expiration: meResponse.exp ?? null,
                    user: meResponse.user
                });

                const accessResponse = await dataClient.access();
                access.value = accessResponse;
            }
        } catch (e: any) {
            if (e.statusCode != undefined) {
                if (e.statusCode == 401) {
                    clearToken();
                }
            } else {
                // TODO: figure out why API calls are returning undefined status codes
                // if (e.statusCode === undefined) {
                //     alert('error: ' + e + 'status code: ' + e.statusCode);
                // }

                // this is a network error, just log it and do nothing for now
                console.error(e);
            }
        }
    }

    async function logout() {
        const isDesktop = !isPlatform('capacitor');
        const redirect = isDesktop ? window.location.href : `${process.env.VUE_APP_API_BASE_URL}/users/me`;
        const url = `${process.env.VUE_APP_API_BASE_URL}/auth/saml/logout?redirect=${encodeURIComponent(redirect)}`;
        const hostname = process.env.VUE_APP_API_BASE_URL.replace(/^https:\/\/|\/api/g, '');

        if (isDesktop) {
            clearToken();
            window.location.href = url;            
            return Promise.resolve();
        }
        
        // Handle mobile logout
        const redirectToLogin = `
            (() => {
                if (window.location.hostname === '${hostname}') {
                    document.body.style.display = "none";
                    webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({ logout: true }));
                }
            })();
        `;
        
        let interval: any;
        const browser = InAppBrowser.create(url, '_blank',"location=no,footer=no,fullscreen=yes,toolbar=no");
        
        FirebaseAnalytics.logEvent(
            { 
                name: 'screen_view', 
                params: {
                    screen_name: 'logout',
                    screen_class: 'InAppView',
                }
            }
        );
        
        browser.on('loadstart').subscribe(() => {
            clearInterval(interval);
            interval = setInterval(() => {
                browser.executeScript({ code: redirectToLogin });
            }, 250);
        });

        browser.on('message').subscribe((message) => {
            if (message.data?.logout === true) {
                // Unsubscribe from push notifications
                const userLocation = user.value?.location?.[0]?.name;
                const topic = userLocation && userLocation.toLowerCase();
                
                FCM.unsubscribeFrom({ topic: 'global' })
                        .then((r) => console.log(r))
                        .catch((err) => console.log(err));

                if (topic) {
                    FCM.unsubscribeFrom({ topic })
                        .then((r) => console.log(r))
                        .catch((err) => console.log(err));
                }

                clearInterval(interval);
                browser.close();
                clearToken();
                dataClient.accessToken = null;
                
                router.push("/");
                setTimeout(() => { // This is needed to give tokens a chance to clear
                    router.go(0);
                }, 500);
            } else {
                clearToken();
                router.push("/");
                router.go(0);
            }
        });
    }

    async function login() {
        const isDesktop = !isPlatform('capacitor');
        const redirect = isDesktop ? window.location.href : `${process.env.VUE_APP_API_BASE_URL}/users/me`;
        const url = `${process.env.VUE_APP_API_BASE_URL}/auth/saml/login?redirect=${encodeURIComponent(redirect)}`;
        const hostname = process.env.VUE_APP_API_BASE_URL.replace(/^https:\/\/|\/api/g, '');

        if (isDesktop) {
            window.location.href = url;
            return Promise.resolve();
        }

        // Handle mobile login
        const getToken = `
            (() => {
                if (window.location.hostname === '${hostname}') {
                    try {
                        document.body.style.display = "none";
                        
                        const json = JSON.parse(document.body.innerText);
                        const { token, user } = json;
                        if (token && user) {
                            const message = JSON.stringify({ token, user });
                            webkit.messageHandlers.cordova_iab.postMessage(message);
                        }
                    } catch(e) {
                        // do nothing
                    }
                }
            })();
        `;

        const styleLogin = `
            (() => {
                try {
                    document.querySelector('#fullPage').style.display = 'flex';
                    document.querySelector('#fullPage').style.alignItems = 'center';
                    document.querySelector('#contentWrapper').style.height = 'auto';
                    document.querySelector('#introduction').innerHTML = '';
                } catch(e) {
                    // do nothing
                }
            })();
        `;

        let interval: any;
        let loginInterval: any;
        const browser = InAppBrowser.create(url, '_blank',"location=no,footer=no,fullscreen=yes,toolbar=no");

        FirebaseAnalytics.logEvent(
            { 
                name: 'screen_view', 
                params: {
                    screen_name: 'login',
                    screen_class: 'InAppView',
                }
            }
        );

        browser.on('loadstart').subscribe((e) => {
            const MAX_RETRIES = 20;
            const url = e?.url;
            
            // If we were redirect back to the cms, then execute the script to get the token
            if (url) {
                const getLocation = function(href: string) {
                    const l = document.createElement("a");
                    l.href = href;
                    return l.hostname;
                };
                const browserHostname = getLocation(url);
                
                if (browserHostname === hostname) {
                    let retries = 0;
                    interval = setInterval(() => {
                        if (retries >= MAX_RETRIES) {
                            clearInterval(interval);
                            return;
                        }
                        browser.executeScript({ code: getToken });
                        retries++;
                    }, 500);
                }

                // only run styleLogin on old login page
                if (browserHostname !== hostname && browserHostname !== 'doitbestcorp.login.duosecurity.com') {
                    let retries = 0;
                    loginInterval = setInterval(() => {
                        if (retries >= MAX_RETRIES) {
                            clearInterval(loginInterval);
                            return;
                        }
                        browser.executeScript({ code: styleLogin });
                        retries++;
                    }, 500);
                }
            }
        });

        browser.on('message').subscribe((message) => {
            // We received a token from our getToken script
            if (message.data?.token && message.data?.user) {
                setToken({
                    expiration: message.data?.exp ?? null,
                    token: message.data.token,
                    user: message.data.user
                });

                // Subscribe to push notifications
                const userLocation = message.data.user?.location?.[0]?.name;
                const topic = userLocation && userLocation.toLowerCase();

                FirebaseAnalytics.setUserId({
                    userId: message.data.user?.id,
                });

                FirebaseAnalytics.setUserProperty({
                    name: "location",
                    value: userLocation || "unknown",
                });

                if (topic) {
                    FCM.subscribeTo({ topic })
                        .then((r) => console.log(r))
                        .catch((err) => console.log(err));
                }

                FCM.subscribeTo({ topic: "global" })
                    .then((r) => console.log(r))
                    .catch((err) => console.log(err));

                dataClient.accessToken = token.value;
                fetchTokenSilently();

                clearInterval(interval);
                clearInterval(loginInterval);

                browser.close();
                router.push("/");
                
                setTimeout(() => {
                    router.go(0);
                }, 1000);
            }
        });
    }

    async function clear() {
        token.value = null;
    }

    function usePermission(path: string) {
        const hasPermission = computed(() => {
            const paths = path.split('.');

            let currentAccess: any = access.value;

            if (currentAccess === null) {
                return false;
            }

            for (const path of paths) {
                if (currentAccess == undefined) {
                    return false;
                }

                currentAccess = currentAccess[path];
            }

            if (path === 'canAccessAdmin') {
                return currentAccess == true;
            }

            return currentAccess.permission == true;
        })

        return {
            path,
            hasPermission
        }
    }

    return {
        token,
        expiration,
        user,
        isAuthenticated,
        access,
        fetchTokenSilently,
        logout,
        login,
        clear,
        usePermission
    }
});
