import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, mergeProps as _mergeProps, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_rich_text_node = _resolveComponent("rich-text-node", true)!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isContainerNode(_ctx.node))
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.node.children ?? [], (child, index) => {
            return (_openBlock(), _createBlock(_component_rich_text_node, {
              node: child,
              key: index
            }, null, 8, ["node"]))
          }), 128))
        ]))
      : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.is), _normalizeProps(_mergeProps({ key: 1 }, _ctx.attributes)), {
          default: _withCtx(() => [
            (_ctx.text !== undefined)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(_ctx.text), 1)
                ], 64))
              : (_ctx.hasChildren(_ctx.node))
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.node.children, (child, index) => {
                    return (_openBlock(), _createBlock(_component_rich_text_node, {
                      node: child,
                      key: index
                    }, null, 8, ["node"]))
                  }), 128))
                : _createCommentVNode("", true)
          ]),
          _: 1
        }, 16)),
    _renderSlot(_ctx.$slots, "default")
  ], 64))
}