import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54831d36"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "card-section"
}
const _hoisted_2 = { class: "ion-padding container" }
const _hoisted_3 = { class: "is-flex mb-3 is-align-items-end" }
const _hoisted_4 = {
  key: 0,
  class: "is-size-3"
}
const _hoisted_5 = { class: "section-title is-size-9-desktop is-size-8-touch mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_resource_card_content = _resolveComponent("resource-card-content")!
  const _component_card_container = _resolveComponent("card-container")!

  return (_ctx.category && _ctx.state.length > 0)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              (_ctx.sectionTitlePrefix)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.sectionTitlePrefix), 1))
                : _createCommentVNode("", true),
              _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.category.label), 1)
            ]),
            (_ctx.state.length > 4)
              ? (_openBlock(), _createBlock(_component_ion_button, {
                  key: 0,
                  class: "is-hidden-mobile is-size-2 ml-4 mb-0 expand-button",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isExpanded = !_ctx.isExpanded)),
                  size: "small",
                  color: "dark",
                  fill: _ctx.isExpanded ? 'solid' : 'outline'
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.isExpanded ? 'Collapse' : 'Expand'), 1)
                  ]),
                  _: 1
                }, 8, ["fill"]))
              : _createCommentVNode("", true)
          ]),
          (_openBlock(), _createBlock(_resolveDynamicComponent(!_ctx.isExpanded ? _ctx.CardGroup : 'div'), {
            class: _normalizeClass({ 'card-grid': _ctx.isExpanded }),
            onLoadMore: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loadMore()))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state, (resource) => {
                return (_openBlock(), _createBlock(_component_card_container, {
                  key: resource.id,
                  href: _ctx.getResourceURL(resource),
                  loading: false,
                  target: "_blank",
                  onClick: ($event: any) => (_ctx.handleCardClick(resource))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_resource_card_content, { resource: resource }, null, 8, ["resource"])
                  ]),
                  _: 2
                }, 1032, ["href", "onClick"]))
              }), 128))
            ]),
            _: 1
          }, 40, ["class"]))
        ])
      ]))
    : _createCommentVNode("", true)
}