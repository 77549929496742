import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b353228"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "masonry-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (columnIndex) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "masonry-column",
        key: columnIndex,
        style: _normalizeStyle('width: calc(' + (1 / _ctx.columns) * 100 + '% - var(--gap));')
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columnItems[columnIndex - 1], (item, itemIndex) => {
          return (_openBlock(), _createElementBlock("div", {
            key: itemIndex + '' + columnIndex,
            class: "item"
          }, [
            _renderSlot(_ctx.$slots, "default", {
              item: item,
              index: itemIndex
            }, undefined, true)
          ]))
        }), 128))
      ], 4))
    }), 128))
  ]))
}